import React from 'react'
import './span-component.scss'
import { SpanProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const Span = ({ text, children, variant, classes }: SpanProps) => {
  return (
    <>
      {children ? (
        <span className={`basic ${variant} ${concatClassName(classes)}`}>
          {text} {children}
        </span>
      ) : (
        <span className={`basic ${variant} ${concatClassName(classes)}`}> {text} </span>
      )}
    </>
  )
}
export { Span }
