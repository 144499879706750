import * as yup from 'yup'
import * as _ from './message'

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const signInSchema = yup.object().shape({
  email: yup.string().required(_.EMAIL_REQUIRED_ERROR).email(_.EMAIL_ERROR),
  password: yup
    .string()
    .required(_.PASSWORD_REQUIRED_ERROR)
    .matches(passwordRegex, _.PASSWORD_ERROR),
})

const signUpSchema = yup.object().shape({
  name: yup.string().required(_.FULL_NAME_REQUIRED_ERROR),
  email: yup.string().required(_.EMAIL_REQUIRED_ERROR).email(_.EMAIL_ERROR),
  password: yup
    .string()
    .required(_.PASSWORD_REQUIRED_ERROR)
    .matches(passwordRegex, _.PASSWORD_ERROR),
  passwordConfirmation: yup
    .string()
    .required(_.CONFIRM_PASSWORD_REQUIRED_ERROR)
    .oneOf([yup.ref('password'), null], _.CONFIRM_PASSWORD_ERROR),
})

const forgotPasswordSchema = yup.object({
  password: yup
    .string()
    .required(_.PASSWORD_REQUIRED_ERROR)
    .matches(passwordRegex, _.PASSWORD_ERROR),
  passwordConfirmation: yup
    .string()
    .required(_.CONFIRM_PASSWORD_REQUIRED_ERROR)
    .oneOf([yup.ref('password'), null], _.CONFIRM_PASSWORD_ERROR),
})

const emailInputSchema = yup.object({
  email: yup.string().required(_.EMAIL_REQUIRED_ERROR).email(_.EMAIL_ERROR),
})

const kycAll = yup.object({
  curriculamVitae: yup.mixed().nullable().required(),
  documentEvidencingSourceOfFundWealth: yup.mixed().nullable().required(),
})
const kycIndividualUploads = kycAll.shape({
  personalIdentityCertificate: yup.mixed().nullable().required(),
  addressVerification: yup.mixed().nullable().required(),
})
const kycLLP = kycAll.shape({
  profileOfTheCorporation: yup.mixed().nullable().required(),
  partnershipAgreementOrLlcOperatingAgreement: yup.mixed().nullable().required(),
  lpOrLlcMandateAuthorizingTheEstablishmentOfTheRelationship: yup.mixed().nullable().required(),
  conferringAuthorityOnThoseWhoIssueInstructions: yup.mixed().nullable().required(),
  registeredAddressVerificationOfCorporation: yup.mixed().nullable().required(),
})
const kycCorporateEntity = kycAll.shape({
  profileOfTheCorporation: yup.mixed().nullable().required(),
  authorizedSignatoryList: yup.mixed().nullable().required(),
  listOfDirectorsNames: yup.mixed().nullable().required(),
  certificateOfIncorporationOrSimilarDocument: yup.mixed().nullable().required(),
  personalIdentityAndAddressVerificationOfAllDirectors: yup.mixed().nullable().required(),
  listOfNamesAndAddressesOfShareholdersHolding10OrMore: yup.mixed().nullable().required(),
})
const kycInvestmentFund = kycAll.shape({
  profileOfTheCorporation: yup.mixed().nullable().required(),
  authorizedSignatoryList: yup.mixed().nullable().required(),
  listOfDirectorsNames: yup.mixed().nullable().required(),
  certificateOfIncorporationOrSimilarDocument: yup.mixed().nullable().required(),
  personalIdentityAndAddressVerificationOfAllDirectors: yup.mixed().nullable().required(),
  writtenConfirmationByTheAdministratorOfTheFund: yup.mixed().nullable().required(),
})
const kycTrust = kycAll.shape({
  trustType: yup.string().required(),
  profileOfTheCorporation: yup.mixed().nullable().required(),
  documentaryEvidenceShowingThatTheTrusteesIsApprovedFinancialInstitution: yup
    .mixed()
    .nullable()
    .when('financialTrust', {
      is: true,
      then: yup.mixed().nullable().required(),
    }),
  documentaryEvidenceShowingThatTheTrusteesIsApprovedNonFinancialInstitution: yup
    .mixed()
    .nullable()
    .when('nonFinancialTrust', {
      is: true,
      then: yup.mixed().nullable().required(),
    }),
  authorizedSignatoryList: yup.mixed().nullable().required(),
  listOfDirectorsNames: yup.mixed().nullable().required(),
  personalIdentityAndAddressVerificationOfAllDirectors: yup.mixed().nullable().required(),
  aCertifiedTrueCopyOfTheTrustDeed: yup.mixed().nullable().required(),
})
const kycPrivateFoundations = kycAll.shape({
  profileOfTheCorporation: yup.mixed().nullable().required(),
  authorizedSignatoryList: yup.mixed().nullable().required(),
  listOfDirectorsNames: yup.mixed().nullable().required(),
  personalIdentityAndAddressVerificationOfAllDirectors: yup.mixed().nullable().required(),
  personalIdentityAndAddressVerificationOfTheFounder: yup.mixed().nullable().required(),
  certificateOfIncorporationOrSimilarDocument: yup.mixed().nullable().required(),
})

const contactUsDetailsSchema = yup.object().shape({
  firstName: yup.string().required(_.FIRST_NAME_REQUIRED_ERROR),
  lastName: yup.string().required(_.LAST_NAME_REQUIRED_ERROR),
  email: yup.string().required(_.EMAIL_REQUIRED_ERROR).email(_.EMAIL_ERROR),
  subject: yup.string().required(),
  message: yup.string().max(500).required(_.MESSAGE_REQUIRED_ERROR),
})
const investorGeneralSchema = yup.object({
  name: yup.string().required(_.isRequiredMessage('Name')),
  countryOfResidence: yup.string().required(_.isRequiredMessage('Country')),
  email: yup.string(),
  phoneNumber: yup.string(),
})

const investorDetailSchema = yup.object({
  passportNumber: yup.string(),
  citizenship: yup.string(),
})

const investmentDetails = yup.object({
  name: yup.string().required(_.isRequiredMessage('Full Name')),
  dob: yup.date().required(_.isRequiredMessage('Date of birth')),
  countryOfResidence: yup.string().required(_.isRequiredMessage('Country')),
  city: yup.string().required(_.isRequiredMessage('City')),
  zip: yup.string().min(5).max(6).required(_.isRequiredMessage('ZIP Code')),
  addressLine1: yup.string().required(_.isRequiredMessage('Address Line 1')),
  addressLine2: yup.string().required(_.isRequiredMessage('Address Line 2')),
  phoneNumber: yup.string().min(10).required(_.isRequiredMessage('Phone number')),
  email: yup.string().required(_.isRequiredMessage('Email')),
})

const contactUsSchema = yup.object({
  name: yup.string().required(_.FULL_NAME_REQUIRED_ERROR),
  email: yup.string().required(_.EMAIL_REQUIRED_ERROR),
  phone: yup.string().min(10).required('Phone number cannot be blank'),
  assistance_category: yup.string().required('Assistance category cannot be blank'),
  subject: yup.string().required('Subject cannot be blank'),
  message: yup.string().required('Message cannot be blank'),
  attachments: yup.array().optional(),
})

export {
  signInSchema,
  signUpSchema,
  forgotPasswordSchema,
  emailInputSchema,
  investorGeneralSchema,
  investorDetailSchema,
  contactUsDetailsSchema,
  kycPrivateFoundations,
  kycTrust,
  kycInvestmentFund,
  kycLLP,
  kycIndividualUploads,
  kycCorporateEntity,
  investmentDetails,
  contactUsSchema,
}
