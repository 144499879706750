import React from 'react'

import './terms-of-business.scss'

const TermsOfBusiness = () => (
  <body className='c41 doc-content'>
    <div>
      <p className='c106 c22'>
        <span className='c85'></span>
      </p>
      <p className='c106 c22'>
        <span className='c85'></span>
      </p>
      <p className='c22 c106'>
        <span className='c85'></span>
      </p>
      <p className='c22 c109'>
        <span className='c84'></span>
      </p>
    </div>
    <p className='c25'>
      <span className='c1'>&nbsp;TERMS OF BUSINESS</span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        These Terms set out the basis upon which Credibila Markets (conducting its operations from
        11
      </span>
      <span className='c15 c99'>th</span>
      <span className='c1'>&nbsp;Floor, Bramer House, Ebene, 72201, Republic of Mauritius</span>
      <span className='c15 c32'>&nbsp;</span>
      <span className='c1'>
        and regulated by the Mauritius Financial Services Commission (&ldquo;
      </span>
      <span className='c18'>MFSC</span>
      <span className='c1'>&rdquo;)) (together, &ldquo;</span>
      <span className='c18'>us</span>
      <span className='c1'>&rdquo;, &ldquo;</span>
      <span className='c18'>we</span>
      <span className='c1'>&rdquo; or the &ldquo;</span>
      <span className='c18'>Portfolio Manager</span>
      <span className='c1'>&rdquo;) provide services to you (the &ldquo;</span>
      <span className='c18'>Client</span>
      <span className='c1'>
        &rdquo;) in accordance with the relevant licences issued to the us, which are as follows:
        (the &ldquo;
      </span>
      <span className='c18'>Licences</span>
      <span className='c1'>&rdquo;)</span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <ul className='c12 lst-kix_list_21-0 start'>
      <li className='c25 c104 li-bullet-0'>
        <span className='c1'>
          Investment Dealer Licence (Full Service Dealer Licence, excluding underwriting); and
        </span>
      </li>
      <li className='c25 c104 li-bullet-0'>
        <span className='c1'>
          Global Business Licence pursuant to Section 72(6) of the Financial Services Act 2007.
        </span>
      </li>
    </ul>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        These are the Terms for certain services that we provide in or from Mauritius or any
        relevant approved branch office of the Portfolio Manager. &nbsp;[Unless otherwise specified
        to you at any time [or unless you expressly request otherwise], the services we provide you
        will be provided in or from Mauritius or any other relevant approved branch office of the
        Portfolio Manager.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        We may provide a range of services to you in relation to your portfolio of investments
        (&ldquo;
      </span>
      <span className='c18'>Portfolio</span>
      <span className='c1'>
        &rdquo;), including, but not limited to, discretionary asset management services, investment
        advisory services and non-discretionary investment services Part A of these Terms applies to
        all services to be provided by us. &nbsp;
      </span>
    </p>
    <p className='c6'>
      <span className='c9'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        We might provide other services that are covered by both these Terms and additional terms.
        &nbsp;If that is the case we will give you the terms that apply.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        If you wish for us to arrange custody services for you, we will arrange for these to be
        provided through a back-to-back arrangement with our preferred partners which are an
        independent licensed bank/investment firm &amp; global custodian. &nbsp;If you do not wish
        for us to provide such services, subject to agreement with us, you may elect to appoint a
        custodian other than us for one or more portfolios. &nbsp;You will ensure that any such
        custodian (i) enters into arrangements regarding the provision of custody services for the
        whole or relevant part of the portfolio which are satisfactory to us and (ii) is obliged to
        comply with any of our instructions given in accordance with these Terms. &nbsp;We will not
        be responsible for supervising any such custodian.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        By signing our Client Agreement, you are deemed to have agreed for yourself and your
        successors and personal representatives to be bound by these Terms (as may be amended,
        restated or replaced from time to time). &nbsp;General terms, conditions and rights implied
        by law shall also apply subject (wherever legally possible) to the Terms. By signing the
        Client Agreement, you confirm that you do not have any direct or indirect restriction in
        your country of residence/incorporation or registration to avail the services that we are
        offering.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c65'>
      <span className='c1'>PART A: GENERAL TERMS AND CONDITIONS</span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c94 c50'>
      <span className='c1'>
        In these General Terms and Conditions, unless the context otherwise requires, a reference to
        one gender shall include all genders, the singular shall include the plural and vice versa,
        and references to persons shall include bodies corporate, unincorporated associations and
        partnerships. &nbsp;Unless the context otherwise requires, references in these General Terms
        and Conditions to any statute or statutory provision shall be construed as a reference to
        the same as it may from time to time be amended, modified or re-enacted.
      </span>
    </p>
    <p className='c94 c50'>
      <span className='c1'>
        Any terms capitalised but not defined in these General Terms and Conditions shall have the
        meaning set out in these Terms more generally.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1 start' start={1}>
      <li className='c52 li-bullet-1'>
        <span className='c18'>Client identification, warranties and liabilities</span>
      </li>
    </ol>
    <p className='c20'>
      <span className='c1'>
        The Client represents and warrants to the Portfolio Manager that it qualifies as a
        &ldquo;Professional Client&rdquo; and that the Client&rsquo;s categorisation as a
        Professional Client is hereby confirmed by the Client executed by the Client prior to or at
        the time of the execution of these General Terms and Conditions]
      </span>
    </p>
    <p className='c33'>
      <span className='c1'>
        The Client acknowledges and accepts that, it has been advised prior to signing this
        agreement that:
      </span>
    </p>
    <p className='c22 c96'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_4-2 start' start={1}>
      <li className='c92 li-bullet-2'>
        <span className='c1'>The Portfolio Manager does not offer any legal or tax advice; </span>
      </li>
      <li className='c92 li-bullet-3'>
        <span className='c1'>
          The Client confirms that they have any direct or indirect restriction in your country of
          residence/incorporation or registration to avail the services that we are offering;
        </span>
      </li>
    </ol>
    <p className='c70 c78'>
      <span className='c1'>
        The signatures provided to the Portfolio Manager in writing are the only signatures that are
        binding upon the Portfolio Manager until notice of revocation in writing. &nbsp;The
        Portfolio Manager shall not be obliged to take into account any differing registrations
        contained in any commercial register or any other publications.
      </span>
    </p>
    <p className='c70 c78'>
      <span className='c1'>The Client warrants and agrees that:</span>
    </p>
    <ol className='c12 lst-kix_list_6-0 start' start={1}>
      <li className='c28 li-bullet-4'>
        <span className='c1'>it has full power and authority to agree to these Terms;</span>
      </li>
      <li className='c28 li-bullet-5'>
        <span className='c1'>
          except as separately notified to the Portfolio Manager in writing, the Client&rsquo;s
          Portfolio(s) is/are free from all liens and charges, and that no liens or charges will
          arise from the Client&rsquo;s acts or omissions;
        </span>
      </li>
      <li className='c28 li-bullet-4'>
        <span className='c1'>
          any information that it has provided the Portfolio Manager in relation to its status and
          domicile for tax, anti-money laundering and/or &ldquo;know your customer&rdquo; (&ldquo;
        </span>
        <span className='c18'>KYC</span>
        <span className='c1'>
          &rdquo;) purposes is complete and correct and that it will promptly provide any further
          information properly required by any competent authority and the relevant laws &amp;
          regulations of Mauritius;
        </span>
      </li>
      <li className='c28 li-bullet-5'>
        <span className='c1'>
          it will promptly provide the Portfolio Manager with any information reasonably requested
          and acknowledge that a failure to provide such information may adversely affect the
          quality of the service that the Portfolio Manager provides;
        </span>
      </li>
      <li className='c28 li-bullet-5'>
        <span className='c1'>
          it will promptly notify the Portfolio Manager if there is any material change in any
          information provided pursuant to paragraphs (c) and (d) above; and
        </span>
      </li>
      <li className='c28 li-bullet-4'>
        <span className='c1'>
          it will be acting as principal and for its own account at all times in relation to the
          services provided by the Portfolio Manager and there is no nominee relationship involved
          directly or indirectly.
        </span>
      </li>
    </ol>
    <p className='c70 c50'>
      <span className='c1'>
        Except insofar as the same results from the Portfolio Manager&rsquo;s or the Portfolio
        Manager&rsquo;s employees&rsquo; gross negligence, wilful default or fraud, the Client
        agrees to indemnify the Portfolio Manager against all costs, losses, claims and expenses
        which may be incurred by the Portfolio Manager or made against the Portfolio Manager either:
        (i) as a result of any party claiming to be entitled to a right of security over or an
        interest in any investments, assets or monies in relation to the Client&rsquo;s
        Portfolio(s); (ii) as a result of any breach by the Client of these Terms; or (iii) arising
        out of or in connection with the exercise of rights or obligations by the Portfolio Manager
        in accordance with the Terms. &nbsp;Where any party claims, or there is a realistic prospect
        that such party may claim, ownership of or a right of security over or an interest in any
        investments or money in the Client&rsquo;s Portfolio, the Client agrees that the Portfolio
        Manager may retain and apply such investments or money (whether in whole or in part) as the
        Portfolio Manager may in its reasonable discretion consider necessary to meet such claim or
        prospective claim or to meet the Portfolio Manager&rsquo;s legal or regulatory obligations
        in relation thereto.
      </span>
    </p>
    <p className='c50 c70'>
      <span className='c1'>
        The Client shall be liable for any damage resulting from civil incapacity on their behalf if
        the Portfolio Manager could not have known of this incapacity using ordinary due diligence.
        The Client shall always be liable for any damage resulting from incapacity on the part of
        any holder of a power of attorney or third party acting on its behalf.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={2}>
      <li className='c46 c63 li-bullet-1'>
        <span className='c18'>Instructions and communications from the Client</span>
      </li>
    </ol>
    <p className='c20'>
      <span className='c1'>
        Instructions from the Client will be acknowledged by the Portfolio Manager acting upon them.
      </span>
    </p>
    <p className='c13'>
      <span className='c1'></span>
    </p>
    <p className='c20'>
      <span className='c1'>
        The Portfolio Manager may rely and act on any instruction or communication which purports to
        have been given by or on behalf of the Client or any person notified to the Portfolio
        Manager by the Client from time to time as being authorised to instruct the Portfolio
        Manager in respect of the services provided and, subject to the provisions below, by
        whatever means transmitted and whether or not the authority of any such person has been
        terminated by the Client (unless the Portfolio Manager has received prior written notice of
        such termination).
      </span>
    </p>
    <p className='c13'>
      <span className='c1'></span>
    </p>
    <p className='c20'>
      <span className='c15'>
        The Portfolio Manager will use reasonable endeavours to act upon instructions received on a
        day that banks are open for business in Mauritius (&ldquo;
      </span>
      <span className='c24'>Business Day</span>
      <span className='c1'>
        &rdquo;) on the day that it receives them, provided that such instructions are received
        before 1PM Mauritian Time. However, the Client acknowledges that certain transactions may
        have specific market cut-off times, details of which are available on request. Instructions
        received at any time on a non-Business Day will be acted upon on the following Business Day.
        The Client also confirms that some transactions might expect delay when cross functional
        currencies are involved if the originating home currency is having any public or bank
        holidays.
      </span>
    </p>
    <p className='c13'>
      <span className='c1'></span>
    </p>
    <p className='c20'>
      <span className='c1'>
        Where the Client requires the Portfolio Manager to act on an instruction before a particular
        deadline, the Client must ensure that it allows reasonable time for the Portfolio Manager to
        process the instruction and communicate it to the relevant third parties, taking into
        account that the Portfolio Manager may require written instructions in some circumstances.
        &nbsp;The Portfolio Manager is not liable for any failure to meet a deadline where clear
        instructions are not received from the Client within a reasonable time before the deadline.
      </span>
    </p>
    <p className='c13'>
      <span className='c1'></span>
    </p>
    <p className='c20'>
      <span className='c1'>
        The Portfolio Manager may refuse to act on an instruction or accept a payment if it
        reasonably believes that:
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-2' start={3}>
      <li className='c69 li-bullet-6'>
        <span className='c1'>
          the instruction is not clear, does not satisfy any requirements that apply to the service
          or product or was not given by the Client or an authorised person;
        </span>
      </li>
      <li className='c69 li-bullet-7'>
        <span className='c1'>
          the client is requesting a third payment without any reasonable explanation or evidence;
        </span>
      </li>
      <li className='c69 li-bullet-8'>
        <span className='c1'>
          by carrying out the instruction the Portfolio Manager, or another Credibila Market&rsquo;s
          group company, might break a law, regulation, code or other duty which applies to the
          Portfolio Manager or become exposed to action or censure from any government, regulator or
          law enforcement agency;
        </span>
      </li>
      <li className='c69 li-bullet-9'>
        <span className='c1'>
          the Portfolio Manager is unable to execute an instruction of the Portfolio Manager is
          unable to identify the identity of the Client when a specific instruction or payment is
          being sent;
        </span>
      </li>
      <li className='c69 li-bullet-10'>
        <span className='c1'>
          The Portfolio Manager has reasonable grounds to suspect that the instruction of payment is
          in relation to illicit/illegal activities as prescribed under the laws of Mauritius;
        </span>
      </li>
      <li className='c69 li-bullet-6'>
        <span className='c1'>
          The Portfolio Manager can refuse to act on any instruction or payment of same is likely to
          bring bad repute to the Mauritius International Financial Services Centre;
        </span>
      </li>
      <li className='c69 li-bullet-6'>
        <span className='c1'>
          it involves a payment to or from a restricted country. &nbsp;The Portfolio Manager will
          inform the Client which countries are &ldquo;restricted&rdquo; on request; or
        </span>
      </li>
      <li className='c69 li-bullet-8'>
        <span className='c1'>
          someone else may have rights over money or investments in a Portfolio, or there is a
          dispute between joint Portfolio holders.
        </span>
      </li>
    </ol>
    <p className='c13'>
      <span className='c1'></span>
    </p>
    <p className='c20'>
      <span className='c1'>
        If the Client wishes to cancel an instruction, it will not be possible to do so if the
        instruction has already been acted upon. &nbsp;If the Client wishes to revoke or cancel an
        instruction, it should telephone the Portfolio Manager as soon as possible, giving details
        of the Portfolio number and the relevant instruction. &nbsp;The Client must confirm its
        instructions in writing as soon as possible. &nbsp;The Portfolio Manager may charge a fee
        for such a cancellation, for which copies of the tariffs are available on request.
      </span>
    </p>
    <p className='c13'>
      <span className='c1'></span>
    </p>
    <p className='c20'>
      <span className='c1'>
        In the case of any damage incurred as a result of the non-execution, late execution or
        faulty execution of an order, the Portfolio Manager shall be responsible only for any loss
        of interest unless it was warned, in a particular case, of the risk of more extensive
        damage. With respect to stock exchange orders, the Portfolio Manager shall not be liable for
        omissions or errors attributable to its auxiliaries.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={3}>
      <li className='c17 li-bullet-11'>
        <span className='c18'>Communications from the Portfolio Manager</span>
      </li>
    </ol>
    <p className='c20'>
      <span className='c1'>
        The Portfolio Manager may contact the Client by post, telephone, fax or electronically using
        the details the Client has provided to the Portfolio Manager. &nbsp;The Portfolio Manager
        may also provide information on its website where it considers it appropriate to do so.
      </span>
    </p>
    <p className='c13'>
      <span className='c1'></span>
    </p>
    <p className='c20'>
      <span className='c1'>
        All written communications from the Portfolio Manager to the Client shall be sent to the
        last mailing address the Client notified to the Portfolio Manager in writing. Unless
        otherwise requested by the Client, Portfolio statements shall be provided by post. &nbsp;The
        date appearing on the copy in the possession of the Portfolio Manager shall be deemed to be
        the date on which the communication was sent and it shall be deemed to be received by the
        Client no later than four Business Days after posting (if sent nationally) or ten Business
        Days after posting (if sent internationally).
      </span>
    </p>
    <p className='c33'>
      <span className='c1'>
        The Client undertakes to notify the Portfolio Manager immediately and without delay of any
        change in the Client&rsquo;s status (nationality, marital status, domicile/ registered
        office, etc.), and shall take the measures necessary to ensure that their assets cannot be
        considered as &ldquo;dormant&rdquo;. If, despite this undertaking, contact should be broken,
        the Portfolio Manager will, at its own discretion or supported by a third party, undertake
        relevant research in the country of residence/incorporation or registration of the Client to
        re-establish contact. Any expenses incurred shall be fully payable by the Client, regardless
        of their amount as far as we are able to provide you relevant documentation to evidence the
        expenses incurred on same.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        To the extent permitted by the laws of Mauritius, the Client shall be liable for any damage
        arising as a result of incorrect verification of identity or forgery that was not detected,
        except in the case of gross negligence on the part of the Portfolio Manager.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        English is the official language for all communications between the Portfolio Manager and
        the Client, although the Portfolio Manager may also communicate with the Client in other
        languages. &nbsp;All communications from the Portfolio Manager to the Client, and the terms
        on which these Terms are concluded and documents containing or amending these Terms will be
        in English.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={4}>
      <li className='c17 li-bullet-12'>
        <span className='c18'>Telephone, fax and e-mail communications</span>
      </li>
    </ol>
    <p className='c19'>
      <span className='c1'>
        Subject to the provisions below, the Client may communicate with the Portfolio Manager
        orally, by fax or e-mail, but the Client hereby acknowledges that if it chooses to
        communicate with the Portfolio Manager by any of these methods the Client does so at its own
        risk. &nbsp;The Client agrees to waive any claim that it may have against the Portfolio
        Manager for breach of the duty of confidentiality (or any similar duty) which may arise
        through the Portfolio Manager acting in good faith in reliance upon an instruction given in
        accordance with this paragraph 4.
      </span>
    </p>
    <p className='c19 c22'>
      <span className='c1'></span>
    </p>
    <p className='c19'>
      <span className='c1'>
        If the Client wishes to communicate with the Portfolio Manager via e-mail it must have
        provided the Portfolio Manager with an e-mail address in writing. &nbsp;By sending the
        Portfolio Manager an e-mail address, the Client is indicating that it is willing for the
        Portfolio Manager to communicate with it by e-mail for any purpose under these Terms.
        &nbsp;The Client acknowledges and accepts that e-mail communications are not secure or
        reliable, that an e-mail may not be read or actioned in a timely manner and that there is a
        risk of technical malfunction, unauthorised interference, misdelivery or delay of e-mail
        messages and a risk of computer viruses. &nbsp;The Client should not send its security
        details or Portfolio information by e-mail, as the Portfolio Manager will not ask for such
        details by e-mail.
      </span>
    </p>
    <p className='c19 c22'>
      <span className='c1'></span>
    </p>
    <p className='c19'>
      <span className='c1'>
        In order to guarantee transaction security, the Portfolio Manager may record telephone
        conversations on certain lines, and the Client expressly accepts this. The recordings are
        only kept for a limited period of time and the Portfolio Manager will only use them in the
        event of any disputes or problems connected with instructions given by telephone.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        Any damage resulting from the use of the postal service, telephone, fax, e-mail or any other
        mode of transmission, or the use of a delivery company, in particular due to any delays,
        losses, misunderstandings, deterioration in condition or items being transmitted or shipped
        twice, shall be borne by the Client except in the case of gross negligence on the part of
        the Portfolio Manager.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={5}>
      <li className='c17 li-bullet-13'>
        <span className='c18'>Right of pledge and set-off</span>
      </li>
    </ol>
    <p className='c20 c34'>
      <span className='c1'>
        By way of a guarantee for all claims resulting from the Portfolio Manager&rsquo;s business
        relationships with the Client, irrespective of their due date or the currency in which they
        are denominated, the Client shall grant the Portfolio Manager a right of pledge and, for
        debts, a right of set-off in respect of all assets held in custody with it or at another
        location for the account of the Client and shall take all steps and execute any
        documentation required by the Portfolio Manager to evidence such pledge. In the event that
        the Client fails to meet their commitments and by means of advance notice given to the
        Client, the Portfolio Manager may, in its discretion, realise the pledged assets through
        private sale or by way of legal proceedings. In particular, the Portfolio Manager may
        realise the Client&rsquo;s stocks (including intermediated stocks), traded on a market, by
        selling them in the market or by appropriating them to cover the value of the secured claim.
        This right applies even if the Client is the subject of an enforcement procedure or a
        restructuring or protection measure.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        These rights shall be valid without prejudice to any other specific or special right or
        guarantee that may have been provided.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        If a new pledge is made by the Client in favour of a third party, this new pledge shall be
        subordinate to the pledge existing in favour of the Portfolio Manager, with the Portfolio
        Manager informing the third party in question accordingly. Furthermore, if the Client sets
        up a new pledge on assets pledged in favour of another third party, the Client authorises
        the Portfolio Manager to provide notice of the existing pledges to the beneficiaries of the
        new pledge.
      </span>
    </p>
    <p className='c33 c93'>
      <span className='c1'>
        If the assets should be replaced by others, the latter shall be subject to the Portfolio
        Manager&rsquo;s right of pledge automatically.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={6}>
      <li className='c17 li-bullet-14'>
        <span className='c18'>Purchase and sale of securities</span>
      </li>
    </ol>
    <p className='c20 c34'>
      <span className='c1'>
        The purchase and sale of securities, foreign currencies and other financial instruments
        carried out through the Portfolio Manager shall be governed by the customs of the stock
        exchanges or markets in question.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c15'>
        The Client confirms to the Portfolio Manager that neither it, nor any designated beneficial
        owner, is/are persons for whom the acquisition of securities could be limited or prohibited
        by the rules governing certain financial markets, in particular the US rules applicable to
        initial public offerings (&ldquo;
      </span>
      <span className='c24'>IPO</span>
      <span className='c1'>
        &rdquo;) and those applicable to the &ldquo;Voluntary IPO Initiative&rdquo; (in the case of
        any doubt about this qualification, instructions may be requested from the Portfolio
        Manager). The Client undertakes to notify the Portfolio Manager promptly of any change in
        its status, or in that of any designated beneficial owners, that might alter this
        qualification. It acknowledges and agrees that the Portfolio Manager could be forced to sell
        the positions affected by these regulations without notice.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={7}>
      <li className='c17 li-bullet-11'>
        <span className='c18'>Joint and sole Portfolios</span>
      </li>
    </ol>
    <p className='c8'>
      <span className='c1'>
        If the Client is operating a joint Portfolio or if otherwise the Client is more than one
        person, the Client&rsquo;s obligations under these Terms are joint and several. &nbsp;The
        Client will be individually as well as jointly responsible for all the obligations in these
        Terms, including the entire amount of any fees, charges or costs on the Client&rsquo;s
        Portfolio. &nbsp;Any notice given to any of the joint Portfolio holders shall be deemed to
        be given to all of the joint Portfolio holders. &nbsp;Unless otherwise agreed with the
        Client in writing, the Portfolio Manager may act on the instructions of any of the joint
        Portfolio holders, meaning that one party to a joint account can withdraw the entire
        Portfolio alone. &nbsp;However, the Client reserves the right to require written
        instructions from each joint Portfolio holder, or a court order, if in the Portfolio
        Manager&rsquo;s discretion it considers that appropriate and fair in the circumstances.
        &nbsp;On the death, insolvency, liquidation or winding up of any one or more joint Portfolio
        holders, these Terms will not terminate and the Portfolio Manager may treat the remaining
        Portfolio holder(s) as the only person(s) entitled to or interest in the relevant
        Portfolio(s).
      </span>
    </p>
    <p className='c45'>
      <span className='c1'>
        If the Client is an individual, in the event that the Client becomes incapable of managing
        his or her affairs, and prior to the registration of any enduring power of attorney or
        lasting power of attorney (including any analogous procedure under other laws) that the
        Client may have granted, or the appointment of a receiver or similar court or judicial
        officer if such a power of attorney has not been granted, the Portfolio Manager will not
        carry out any further transactions on the Portfolio except that the Portfolio Manager will
        respond to corporate events (such as rights issues/scrip dividends) by following its policy
        for that event, and the Portfolio Manager will retain cash for holdings which mature.
      </span>
    </p>
    <p className='c45'>
      <span className='c1'>
        The Portfolio Manager&rsquo;s authority under these Terms is given by the Client on behalf
        of its successors in title as well as the Client itself. &nbsp;Accordingly, for an
        individual Client, on his or her death, these Terms will continue in effect unless and until
        terminated by the Client&rsquo;s personal representatives in accordance with the provisions
        of these General Terms and Conditions. &nbsp;The Portfolio Manager may (but, prior to
        receiving a certified copy of any grant of representation, are not bound) to act on the
        instructions of the Client&rsquo;s personal representatives. &nbsp;Prior to receipt by the
        Portfolio Manager of a grant of representation, the Portfolio Manager will not carry out any
        further transactions on the Portfolio, except that the Portfolio Manager will respond to
        corporate events (such as rights issues/scrip dividends) by following its policy for that
        event, and the Portfolio Manager will retain cash for holdings which mature.
      </span>
    </p>
    <p className='c45'>
      <span className='c1'>
        These Terms do not create any right or benefit enforceable by any person or persons not
        party to them, except that the Portfolio Manager&rsquo;s affiliated companies may enforce
        rights as expressed in these Terms.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={8}>
      <li className='c17 li-bullet-12'>
        <span className='c18'>Bills of exchange, cheques and other similar instruments</span>
      </li>
    </ol>
    <p className='c55'>
      <span className='c1'>
        The Portfolio Manager may debit the Client&rsquo;s account for bills of exchange, cheques
        and other similar instruments, credited or discounted, if they were not paid when due or
        presented.
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>
        Until payment of the debit balance, the Portfolio Manager shall however retain against any
        obligee pursuant to the said instrument the right to payment in full of the bill of
        exchange, cheque and any other similar instrument as well as payment in full of any
        ancillary debts.
      </span>
    </p>
    <p className='c74 c22 c79'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={9}>
      <li className='c64 c74'>
        <span className='c18'>Portfolio Manager remuneration </span>
      </li>
    </ol>
    <p className='c55'>
      <span className='c1'>
        The Client shall pay the Portfolio Manager for the services it provides in accordance with
        the fees set by the Portfolio Manager. The Client acknowledges that it has received, noted
        and accepted the Portfolio Manager&rsquo;s tariffs.
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>
        The Portfolio Manager reserves the right to modify these fees at any time, as well as the
        dates on which debits are made from the Client&rsquo;s account and such modifications shall
        take effect 20 days after the giving of notice of the change. &nbsp;If the Client does not
        wish to continue receiving any services on such revised terms, the Client is free to
        terminate the Portfolio Manager&rsquo;s appointment in accordance with the provisions of
        these General Terms and Conditions.
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>
        The Client is also liable for transaction charges (as set out from time to time in the
        tariff of charges provided to the Client by the Portfolio Manager), together with any costs
        properly incurred in accordance with these Terms, including reasonable commissions, transfer
        and registration fees, taxes, stamp duties and other fiscal liabilities.
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>
        The Portfolio Manager may pass on to the Client any third party brokerage or other charges
        incurred for transactions effected on the Client&rsquo;s behalf. &nbsp;Such charges will be
        included on the relevant contract note or advice.
      </span>
    </p>
    <p className='c7 c80'>
      <span className='c1'>
        The Client authorises the Portfolio Manager to debit their account in respect of fees,
        commission and any agreed or customary expenses, unless otherwise agreed in writing.
        &nbsp;Fees based on a percentage of the Portfolio will be calculated monthly using the value
        of the Client&rsquo;s portfolio on the last day of the quarter and charged in arrears,
        normally within one month of the quarter end, and will increase as the value of the
        Portfolio grows. &nbsp;Fees shall accrue on a daily basis and will be charged pro rata for
        any part period at the opening or closing of a Portfolio. &nbsp;Money received or accrued
        for the Client&rsquo;s benefit during the quarter in relation to any investments held, if
        the Client is entitled to it under these Terms, will be credited to the Client&rsquo;s
        account when the monthly fee is debited or, if practicable, sooner. &nbsp;Prior notice will
        be given of any periodic charges to be debited. &nbsp;Transaction charges and dealing
        commissions will be debited at the time of the transaction. &nbsp;Should there be
        insufficient cash within the account to cover fees, interest or other sums due, the
        Portfolio Manager may, at its discretion, sell other assets (including investments) in order
        to obtain payment.
      </span>
    </p>
    <p className='c7 c80'>
      <span className='c1'>
        In the case of any non-market standard settlements, additional charges may be made, details
        of which will be supplied on request.
      </span>
    </p>
    <p className='c7'>
      <span className='c76'>
        [The Client has been informed that the Portfolio Manager is operating from the Republic of
        Mauritius and agree that the Portfolio Manager or its affiliated companies/approved branch
        offices may directly or indirectly receive payments and advantages from affiliated companies
        or third parties, including in the form of commission, custody fees, retrocessions or
        brokerage fees. &nbsp;The Portfolio Manager or its affiliated companies may, in particular,
        be induced to receive remuneration in connection with the assets the Client has invested in
        collective investment vehicles, structured products or other financial products whether or
        not an entity in the Credibila Markets&rsquo; group acts as promoter, Portfolio Manager or
        adviser to such vehicles, structured products or other financial products. &nbsp;The details
        of the calculation{' '}
      </span>
      <span className='c15'>
        parameters and the range of percentages used to calculate the amounts the Portfolio Manager
        receives or may receive in this respect are set out in the tariffs provided by the Portfolio
        Manager to the Client.
      </span>
      <span className='c76'>&nbsp; </span>
      <span className='c15'>
        The Client expressly accepts that the Portfolio Manager shall keep such payments.]
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>.</span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={10}>
      <li className='c17 li-bullet-15'>
        <span className='c18'>Conflicts of interest</span>
      </li>
    </ol>
    <p className='c74 c79'>
      <span className='c1'>
        The Portfolio Manager may engage in other business activities, particularly in the sphere of
        management for the benefit of third parties, which might bring its interests into conflict
        with those of the Client. The Portfolio Manager may also have proprietary interests which
        diverge from the Client&rsquo;s interests, particularly regarding investments in collective
        capital investment schemes or other financial products which the Portfolio Manager manages,
        advises on, promotes or is connected with in any other capacity, or for which the Portfolio
        Manager could receive payment or other benefits.
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>
        Where the Portfolio Manager is aware of a conflict of interest it shall manage the conflict
        of interest by adopting all or some of the following:
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-2' start={11}>
      <li className='c43 li-bullet-2'>
        <span className='c1'>
          establishing Chinese walls to restrict the communication of information;
        </span>
      </li>
      <li className='c110 li-bullet-16'>
        <span className='c1'>declaring the conflict of interest to the Client; and/or</span>
      </li>
      <li className='c105 li-bullet-2'>
        <span className='c1'>
          relying on a written policy of independence which would require disregarding the conflict
          of interest.
        </span>
      </li>
    </ol>
    <p className='c46 c79'>
      <span className='c1'>
        If the Portfolio Manager cannot manage the conflict of interest it will not act for the
        Client.
      </span>
    </p>
    <p className='c16'>
      <span className='c1'>
        The Client also acknowledges and accepts the fact that the Portfolio Manager or its
        affiliated companies may be induced to grant remuneration to third parties such as
        independent asset Portfolio Managers appointed by the Client and other intermediaries.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={11}>
      <li className='c17 li-bullet-12'>
        <span className='c18'>Portfolio Manager&rsquo;s liability</span>
      </li>
    </ol>
    <p className='c8'>
      <span className='c1'>
        The Portfolio Manager will act in good faith and with due diligence in performing its duties
        and obligations, including but not limited to the selection, use and monitoring of nominees,
        custodians (unless such custodians are selected by the Client), sub-custodians, agents and
        delegates. &nbsp;The Portfolio Manager accepts responsibility for loss to the Client to the
        extent that such loss is due to the Portfolio Manager&rsquo;s or the Portfolio
        Manager&rsquo;s employees&rsquo; gross negligence, wilful default, breach of FSC rules or
        fraud. &nbsp;
      </span>
    </p>
    <p className='c45'>
      <span className='c1'>
        The Portfolio Manager will not be liable for any direct or indirect loss or loss of profit
        or opportunity the Client may suffer as a result of the Portfolio Manager being unable to
        carry out any of its instructions, or to provide any service or product, as a result of any
        applicable law, rule, obligation or circumstances reasonably beyond the Portfolio
        Manager&rsquo;s control (including, but not limited to, any market disruption, interruption
        of payment or clearing services, industrial action, equipment failure, computer or related
        software failure, act of any governmental authority, legal constraint, fire, flood,
        epidemic, civil disturbance or unrest, criminal or terrorist activity or interruption of
        communication facilities).
      </span>
    </p>
    <p className='c46 c50'>
      <span className='c1'>
        No warranty is given by the Portfolio Manager as to the performance or profitability of any
        Portfolio or any part of it and the Portfolio Manager will not be liable for any losses
        howsoever arising from decisions taken in reliance on information provided or opinions
        expressed pursuant to these Terms.
      </span>
    </p>
    <p className='c46 c50'>
      <span className='c1'>
        The Client hereby confirms and acknowledges that the Portfolio Manager, together with any
        nominee, custodian, sub-custodian, agent or delegate appointed by the Portfolio Manager in
        accordance with these Terms, is acting solely on the basis of information and facts
        concerning the Client&rsquo;s personal and financial circumstances which have been expressly
        disclosed to the Portfolio Manager by the Client.
      </span>
    </p>
    <p className='c46 c50'>
      <span className='c1'>
        The Portfolio Manager shall not be responsible for any loss of opportunity whereby the value
        of investments could have been increased or for any decline in the value of investments or
        any taxation changes unless such decline or loss or charge is the direct result of the
        Portfolio Manager&rsquo;s wilful default or gross negligence.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={12}>
      <li className='c17 li-bullet-14'>
        <span className='c18'>Client Money, Client assets and safe custody</span>
      </li>
    </ol>
    <p className='c77'>
      <span className='c15 c32'>Client Money</span>
    </p>
    <p className='c70 c79'>
      <span className='c15'>Where the Portfolio Manager holds or controls &ldquo;</span>
      <span className='c24'>Client Money</span>
      <span className='c1'>
        &rdquo;), the Portfolio Manager will ensure that the Client Money is paid into one or more
        Client accounts within 10 Business days of receipt. Client monies are kept segregated as
        required in the licensing conditions of our Licences as imposed by the MFSC as amended from
        time to time.
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>
        If the Portfolio Manager receives Client Money from the Client, then the following
        provisions apply:
      </span>
    </p>
    <ol className='c12 lst-kix_list_3-0 start' start={1}>
      <li className='c46 c64'>
        <span className='c1'>
          the money will be held separately from money which belongs to the Portfolio Manager and
          kept in segregated client accounts;
        </span>
      </li>
      <li className='c70 c64'>
        <span className='c1'>
          if the Portfolio Manager becomes insolvent, is wound up or is the subject of some other
          &ldquo;
        </span>
        <span className='c18'>Insolvency Event</span>
        <span className='c1'>
          &rdquo; then the Client&rsquo;s Money will be the subject to the Insolvency Act of
          Mauritius, which shall be amended from time to time and other relevant laws &amp;
          regulations of Mauritius and as per directives that might be issued by the MFSC
        </span>
      </li>
    </ol>
    <p className='c7'>
      <span className='c1'>
        Money held for and on behalf of the Client in a Client Account will not be subject to the
        payment of interest.
      </span>
    </p>
    <p className='c7'>
      <span className='c1'>
        Where Client Money is held by a third party agent in accordance with these Terms, it may be
        held in a jurisdiction outside Mauritius where the applicable market practices, insolvency
        and legal regime may be different to the regime which applies in Mauritius
      </span>
    </p>
    <p className='c46 c79'>
      <span className='c1'>
        The Portfolio Manager will pass segregated Client Money to a third party agent where:
      </span>
    </p>
    <ol className='c12 lst-kix_list_2-0 start' start={1}>
      <li className='c70 c64 c80'>
        <span className='c1'>
          the Client Money is to be used in respect of a transaction or series of transactions for
          the Client;
        </span>
      </li>
      <li className='c46 c95 li-bullet-17'>
        <span className='c1'>
          the Client Money is to be used to discharge an obligation of the Client; or
        </span>
      </li>
      <li className='c111 li-bullet-18'>
        <span className='c1'>
          the third party agent is a bank which is authorized to take deposits.
        </span>
      </li>
    </ol>
    <p className='c70 c79'>
      <span className='c1'>
        Notwithstanding the Portfolio Manager holding Client Money on behalf of the Client, the
        Portfolio Manager will be entitled to realise Client Money where the Client is in default,
        either to the Portfolio Manager or in respect of settlement of a transaction with a third
        party and the Portfolio Manager would otherwise be liable to settle that transaction.
      </span>
    </p>
    <p className='c79 c90'>
      <span className='c15 c32'>Arranging Custody</span>
    </p>
    <p className='c74 c79'>
      <span className='c1'>
        Where the Portfolio Manager arranges custody for and on behalf of the Client with a third
        party agent, the Client&rsquo;s safe custody investments may be held outside the Mauritius
        and the market practices, insolvency regime applicable in that jurisdiction may be different
        to the regime applicable in Mauritius
      </span>
    </p>
    <p className='c46 c79'>
      <span className='c15 c32'>Providing Custody </span>
    </p>
    <p className='c70 c79'>
      <span className='c1'>
        Where the Portfolio Manager provides custody to the Client through registered &amp;
        regulated global custodian, , the Client will get the benefit of the protections provided by
        the provisions applicable to custody in Part D of these Terms which custody services are
        provided through an independent regulated firm with which we have developed working
        relationships.
      </span>
    </p>
    <p className='c70 c79'>
      <span className='c1'>
        &nbsp;The Portfolio Manager may provide the following services through a back-to-back
        arrangement with the Custodian.:
      </span>
    </p>
    <ol className='c12 lst-kix_list_1-0 start' start={1}>
      <li className='c64 c94'>
        <span className='c1'>
          holding Client assets in a clearly labelled client account &amp; kept segregated;
        </span>
      </li>
      <li className='c64 c103'>
        <span className='c1'>
          collecting all distributions and dividends and any other entitlements relating to the
          Client assets and accounting for them to the Client;
        </span>
      </li>
      <li className='c7 c89 c57'>
        <span className='c1'>
          advising the Client about corporate actions or other events which affect the
          Client&rsquo;s assets and use its best endeavours to act in accordance with the
          instructions of the Client in relation to the corporate actions;
        </span>
      </li>
      <li className='c7 c89 c57'>
        <span className='c1'>
          hold Client Money on behalf of the Client where required and in order to provide the above
          services; and/or
        </span>
      </li>
      <li className='c2 c89'>
        <span className='c1'>
          transferring Client assets to the Client or as the Client may direct on termination of
          these services.
        </span>
      </li>
    </ol>
    <p className='c7 c57'>
      <span className='c1'>
        Where an amount is due from the Client to the Portfolio Manager under or in connection with
        the provision of custody by the Portfolio Manager, the Portfolio Manager may suspend
        transactions in Client assets which the Custodian &nbsp;holds for that Client and where it
        considers it necessary to protect its own interests, it may sell some or all of the Customer
        assets and may apply the proceeds to pay any outstanding amount.
      </span>
    </p>
    <p className='c2'>
      <span className='c1'>
        The Portfolio Manager will provide a report to the Client which contains the details in
        relation to the portfolio, once every quarter, or more frequently if requested by the client
        or any other reporting.
      </span>
    </p>
    <p className='c7 c57'>
      <span className='c1'>
        Client investments will be registered collectively in the same name for all clients and
        accordingly the individual entitlements of each Client may not be identifiable by separate
        certificates or other physical documents of title; however, the Custodian is required to
        maintain sufficient records to identify the entitlement of each client.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={13}>
      <li className='c17 li-bullet-19'>
        <span className='c18'>Treatment of Saturdays &amp; Sundays as bank holidays</span>
      </li>
    </ol>
    <p className='c55'>
      <span className='c1'>
        In all dealings with the Portfolio Manager, Saturdays &amp; Sundays are treated as if they
        were official bank holidays.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={14}>
      <li className='c17 li-bullet-20'>
        <span className='c18'>Data protection and confidentiality</span>
      </li>
    </ol>
    <p className='c49'>
      <span className='c1'>
        The parties to these Terms shall not, except as set out below, disclose information of a
        confidential nature acquired in consequence of them, except for information that they are
        entitled or bound to disclose by law or which is requested by regulatory or fiscal
        authorities, or which is disclosed to their advisers or auditors or agents where reasonably
        necessary for the performance of their professional services or the protection of their
        interests. &nbsp;The Portfolio Manager may disclose the information it holds about the
        client to affiliated companies, but only if they agree to keep that information confidential
        to the same extent and use it only for permitted purposes.
      </span>
    </p>
    <p className='c49 c22'>
      <span className='c1'></span>
    </p>
    <p className='c74 c57 c79'>
      <span className='c1'>
        The Portfolio Manager may also disclose information about the Client to any agents appointed
        in accordance with these Terms and to any depository, stock exchange, clearing or settlement
        system, account controller or other participant in the relevant system, to counterparties,
        dealers, custodians, intermediaries and others where disclosure is reasonably intended for
        the purpose of effecting transactions in connection with these Terms or establishing a
        relationship with a view to such transactions. &nbsp;Generally, the Portfolio Manager may
        disclose information where disclosure is reasonably intended to assist in the performance of
        services or obligations in connection with these Terms or to comply with regulations in any
        part of the world relating to the prevention of money-laundering, terrorism, fraud or other
        crimes. &nbsp;As part of this, the Client authorises the Portfolio Manager to disclose its
        identity and/or account details and/or documents evidencing its identity and/or other
        required information where requested by administrators of any collective investment scheme
        or providers of other products or services that the Portfolio Manager obtains on the
        Client&rsquo;s behalf, by issuers of any securities held in the Client&rsquo;s Portfolio, by
        the MFSC or by any payment service provider.
      </span>
    </p>
    <p className='c49 c22'>
      <span className='c1'></span>
    </p>
    <p className='c49'>
      <span className='c15'>
        The Portfolio Manager may collect, process and use data, namely personal and sensitive data
        relating to the Client, in accordance with the Data Protection Act &amp; relevant Data
        Protection rules in Mauritius which may be amended from time to time (&ldquo;
      </span>
      <span className='c24'>DP Law</span>
      <span className='c1'>
        &rdquo;), in particular for internal marketing purposes, including data concerning their
        asset situation. The Portfolio Manager may retain personal data for such period as it
        considers necessary to comply with its legal or regulatory obligations or to defend any
        potential claim. &nbsp;The Client hereby consents to such collection, processing, use and
        storage of its data by the Portfolio Manager.
      </span>
    </p>
    <p className='c49 c22'>
      <span className='c1'></span>
    </p>
    <p className='c49'>
      <span className='c1'>
        Such data may also be used by the Portfolio Manager and its agents and affiliated companies
        to update client records, to help prevent fraud, and to research, develop and advise the
        Client of other products and services, unless the Client has indicated otherwise.
      </span>
    </p>
    <p className='c49 c22'>
      <span className='c1'></span>
    </p>
    <p className='c49'>
      <span className='c1'>
        If the Client is an individual, it hereby consents to the Portfolio Manager undertaking
        searches taking up references and making such other enquiries as the Portfolio Manager deems
        necessary, including the use of electronic identity verification and credit reference
        agencies for the purposes of verifying the Client&rsquo;s identity. &nbsp;To do so, such
        agencies may check the details supplied by the Client against any particulars on any
        database (public or otherwise) to which they have access. &nbsp;The Portfolio Manager may
        disclose to such agencies details about the Client if it defaults on a debt. &nbsp;Such
        agencies may also use the Client&rsquo;s details in the future to assist other companies for
        verification and credit reference purposes. &nbsp;A record of the search will be retained by
        the agency.
      </span>
    </p>
    <p className='c7 c57'>
      <span className='c1'>
        The executive bodies, employees and agents of the Portfolio Manager are required by law to
        maintain Portfolio Manager-Client confidentiality. The Client nevertheless releases them
        from their duty of confidentiality to the extent necessary for the Portfolio Manager to
        defend its own legitimate interests, particularly where legal provisions or investment
        conditions applicable to foreign securities (or book-entry securities) transactions require
        disclosure of the identity of the Client or the beneficial owner of the assets in the
        account, and of the composition of its Portfolio.
      </span>
    </p>
    <p className='c2'>
      <span className='c1'>
        Pursuant to relevant legal requirements, if the Portfolio Manager suspects that the Client
        is involved in money laundering activities, it shall communicate its evidence to the
        relevant authorities in Mauritius.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={15}>
      <li className='c17 li-bullet-13'>
        <span className='c18'>Outsourcing and delegation</span>
      </li>
    </ol>
    <p className='c49'>
      <span className='c1'>
        In accordance with the rules of Mauritius, the Portfolio Manager reserves the right to
        delegate to third parties or parties of its same group, at any time that it deems expedient,
        and in its discretion, the provision of certain services relating to its activity, such as
        the provision of custody, the processing of accounts, the operation of hardware and
        software, the printing and dispatch of financial statements, securities transactions and
        their administration, payment services and other back-office duties.
      </span>
    </p>
    <p className='c22 c49'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={16}>
      <li className='c17 li-bullet-15'>
        <span className='c18'>Compliance and taxation</span>
      </li>
    </ol>
    <p className='c49'>
      <span className='c1'>
        In relation to the services provided, the Portfolio Manager will, when possible, take into
        consideration any investment restrictions disclosed by the Client relating to the
        Client&rsquo;s tax position. &nbsp;Subject to this, the Portfolio Manager will not be
        responsible for ensuring that the Client does not suffer any adverse tax consequences as a
        result of the Portfolio Manager providing any services in accordance with these Terms or for
        providing the Client with tax advice and the Client should consult its own tax advisers in
        relation to its tax affairs. &nbsp;These Terms do not refer to all taxes and costs which the
        Client may have to pay in relation to its Portfolio or the services.
      </span>
    </p>
    <p className='c49 c22'>
      <span className='c1'></span>
    </p>
    <p className='c49'>
      <span className='c1'>
        The Client is responsible for complying with the legal and regulatory provisions applicable
        to them. This includes, in particular, meeting their tax obligations (filing a tax return,
        paying taxes etc.).
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={17}>
      <li className='c17 li-bullet-21'>
        <span className='c18'>Default remedies</span>
      </li>
    </ol>
    <p className='c8'>
      <span className='c15'>The following are each an &ldquo;</span>
      <span className='c24'>Event of Default</span>
      <span className='c1'>&rdquo;:</span>
    </p>
    <ol className='c12 lst-kix_list_4-2' start={14}>
      <li className='c82 li-bullet-10'>
        <span className='c1'>
          the Client fails to make payment or delivery due under any transaction or under these
          Terms;
        </span>
      </li>
      <li className='c82 li-bullet-10'>
        <span className='c1'>
          the Client is in breach of any other material term or condition, representation or
          warranty in these Terms or any transaction;
        </span>
      </li>
      <li className='c82 li-bullet-10'>
        <span className='c1'>
          the Client is unable to pay its debts as they fall due or undergoes or suffers an
          insolvency process or any analogous process in relation to the Client or the
          Client&rsquo;s property anywhere in the world;
        </span>
      </li>
      <li className='c82 li-bullet-8'>
        <span className='c1'>
          if the Client is an individual, he or she dies or is declared incompetent or
          incapacitated;
        </span>
      </li>
      <li className='c82 li-bullet-7'>
        <span className='c1'>
          the Portfolio Manager is unable to obtain proper instructions from the Client having used
          reasonable efforts to do so; or
        </span>
      </li>
      <li className='c82 li-bullet-6'>
        <span className='c1'>
          the custody or investment management of all or a material part of the Portfolio is removed
          from the Portfolio Manager or the Portfolio Manager&rsquo;s control or that of any of its
          affiliated entities.
        </span>
      </li>
    </ol>
    <p className='c39'>
      <span className='c1'>
        If the Client fails to make any payment under, or in connection with, these Terms or a
        transaction:
      </span>
    </p>
    <ol className='c12 lst-kix_list_12-0 start' start={1}>
      <li className='c58 li-bullet-22'>
        <span className='c1'>
          the Portfolio Manager may, upon written notice to the Client, sell or otherwise dispose of
          all or any such investments in the Portfolio at a price and in such a manner as the
          Portfolio Manager may in its discretion think fit and apply the proceeds of such sale(s)
          towards the costs incurred and then towards any amount due and outstanding to the
          Portfolio Manager or an affiliated entity. &nbsp;In this event the Portfolio Manager will
          not be responsible for any loss or diminution in prices; and
        </span>
      </li>
      <li className='c58 li-bullet-23'>
        <span className='c1'>
          interest will be payable by the Client on any amount due and outstanding at a rate of 5%
          above the Portfolio Manager&rsquo;s cost of borrowing funds for the relevant currency,
          such interest to accrue on a daily basis.
        </span>
      </li>
    </ol>
    <p className='c39'>
      <span className='c1'>If the Client fails to deliver securities:</span>
    </p>
    <ol className='c12 lst-kix_list_13-0 start' start={1}>
      <li className='c59 c101 li-bullet-24'>
        <span className='c1'>
          the Portfolio Manager may buy securities to cover any open and undelivered positions and
          debit the Client&rsquo;s account with all associated costs incurred;
        </span>
      </li>
      <li className='c101 c59 li-bullet-25'>
        <span className='c1'>
          if a buying-in notice is issued against the Portfolio Manager, it will debit the Client
          with the costs incurred; and
        </span>
      </li>
      <li className='c101 c59 li-bullet-26'>
        <span className='c1'>
          the Portfolio Manager reserves the right to debit the Client with any fines imposed due to
          late delivery.
        </span>
      </li>
    </ol>
    <p className='c39'>
      <span className='c1'>
        Without prejudice to other security interests, pledge and set-off rights in these Terms or
        arising by operation of law, the Client agrees that the Portfolio Manager may, without prior
        notice to the Client, set off any payment obligation owed by the Client to the Portfolio
        Manager or any affiliated entity against any payment obligation (whether or not matured)
        owed by the Portfolio Manager or an affiliated entity to the Client in connection with these
        Terms or any transaction, regardless of the place of payment or currency of either
        obligation (and for this purpose the Portfolio Manager may purchase on currency with the
        other at such rate as it reasonably determines).
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        If any obligation is uncertain, unliquidated or unascertained, the Portfolio Manager may set
        off an amount estimated by the Portfolio Manager in good faith to be the amount of that
        obligation and, when the amount is known the relevant party, shall credit the other with any
        additional amount to reflect the difference between the estimate and the actual amount of
        that obligation.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        On the occurrence of an Event of Default, the Portfolio Manager may carry out set-off and
        close-out netting of all outstanding transactions between the Client and the Portfolio
        Manager and/or any affiliated entity, and calculate the net amount of the sums owed to, or
        by, the Portfolio Manager or an affiliated entity under each transaction. &nbsp;
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        As continuing security for the proper payment and discharge of all the Client&rsquo;s
        obligations, actual or contingent, present or future, for payment or delivery or to
        indemnify the Portfolio Manager under these Terms (including interest, costs, charges and
        other expenses), now or at any time becoming due or owing to the Portfolio Manager or any
        affiliated entity under or in connection with these Terms or any transaction, and without
        prejudice to other rights the Portfolio Manager has under these Terms, the Client grants to
        the Portfolio Manager for itself and for each affiliated entity a security interest over all
        rights and interests it may have now or in the future in respect of or in connection with
        the Portfolio, and over any other assets held or controlled by the Portfolio Manager or by
        any affiliated entity which belong to the Client.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        This security interest is a continuing security and will not be satisfied by any
        intermediate payment of satisfaction of the whole or any part of the Client&rsquo;s
        obligations, and will not be affected by any other security interest now or subsequently
        held by the Portfolio Manager or any affiliated entity for all or any of the Client&rsquo;s
        obligations.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        The security interest will become immediately enforceable and the power of sale and other
        powers conferred by law will be exercisable by the Portfolio Manager following an Event of
        Default without notice to the Client.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        The Client will, at its own expense, execute and do all such assurance, acts and things as
        the Portfolio Manager may reasonably require for perfecting or protecting this security
        interest or for facilitating the realisation of the secured assets or any part of them.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        The Portfolio Manager will not be obliged to deliver to the Client any assets in a Portfolio
        until it has discharged its obligations to the Portfolio Manager and its affiliated entities
        in full under these Terms and any transaction.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        The security interest and set-off rights in this paragraph 17 shall take effect over the
        Client&rsquo;s share of any jointly-owned Portfolio.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        The Client agrees that, as its agent, the Portfolio Manager may execute any transfer of
        securities or other documents, given any necessary instructions and generally act for the
        purpose of giving the Portfolio Manager or its agents the power to exercise its default
        remedies under this paragraph 17.
      </span>
    </p>
    <p className='c39'>
      <span className='c1'>
        The default remedies in this paragraph 17 shall be exercisable by the Portfolio
        Manager&rsquo;s affiliated entities to the same extent as they are exercisable by the
        Portfolio Manager.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={18}>
      <li className='c17 li-bullet-14'>
        <span className='c18'>Termination of business relations</span>
      </li>
    </ol>
    <p className='c66'>
      <span className='c1'>
        These Terms do not have a fixed duration and will continue for an indefinite period, unless
        terminated by the Portfolio Manager or the Client in accordance with the following
        provisions.
      </span>
    </p>
    <p className='c2'>
      <span className='c1'>
        The Client may terminate the Portfolio Manager&rsquo;s appointment on one month&rsquo;s
        written notice to the Portfolio Manager in accordance with these Terms.
      </span>
    </p>
    <p className='c2'>
      <span className='c1'>
        The Portfolio Manager may terminate these Terms by immediate notice in accordance with these
        Terms if so required by any competent regulatory authority or in exceptional circumstances
        (for example, suspected fraud or criminal activity). &nbsp;The Portfolio Manager may, for
        example, close a Portfolio that remains below the applicable minimum size for a long period.
      </span>
    </p>
    <p className='c2'>
      <span className='c1'>
        The Portfolio Manager can close the Client&rsquo;s Portfolio immediately if: the Portfolio
        Manager reasonably suspects that the Client has given false information; the Portfolio
        Manager suspects that the Client&rsquo;s account is being used for an illegal purpose; the
        Client behaves in a threatening or violent manner towards the Portfolio Manager&rsquo;s
        staff; the Portfolio Manager is ordered to do so by a court or authority or regulator of
        competent jurisdiction; or the Client was not entitled to open the Portfolio or no longer
        meets the requirements for such Portfolio.
      </span>
    </p>
    <p className='c2'>
      <span className='c1'>
        The Client may be treated by the Portfolio Manager as having served notice to end these
        Terms in relation to a Portfolio if the Client does not make a payment into the Portfolio
        within 6 months of opening it.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={19}>
      <li className='c26 li-bullet-13'>
        <span className='c18'>Consequences of termination</span>
      </li>
    </ol>
    <p className='c8'>
      <span className='c1'>
        On termination, the Portfolio Manager will promptly account to the Client for any assets
        held by the Portfolio Manager in a Portfolio and direct the Portfolio Manager&rsquo;s
        custodian and any nominee company to do likewise. &nbsp;However, the Portfolio Manager or
        any affiliated entity holding or controlling assets shall be entitled to retain and/or
        realise, or direct the retention and/or realisation of, any assets of a Portfolio as may be
        required to settle transactions already initiated, to pay any of the Client&rsquo;s
        outstanding liabilities, including accrued fees or charges or to fulfil any obligation to
        indemnify the Portfolio Manager. &nbsp;If the Client does not promptly following termination
        give the Portfolio Manager details of to whom the assets in the Portfolio(s) should be
        transferred, the Portfolio Manager may convert all of such assets into cash.
      </span>
    </p>
    <p className='c67'>
      <span className='c1'>
        If the Client initiates a change of custodian, the Client shall be responsible for any
        reasonable transfer or re-registration costs.
      </span>
    </p>
    <p className='c67'>
      <span className='c1'>
        Termination will be without prejudice to the completion of transactions already initiated,
        which will be completed by the Portfolio Manager. &nbsp;Termination will also not affect
        accrued rights, indemnities, existing commitments or any contractual provision intended to
        survive termination and will be without penalty or other additional payment, save as set out
        in these Terms. &nbsp;The Client shall pay (i) the Portfolio Manager&rsquo;s fees pro rata
        to the date of termination, (ii) any additional expenses or costs necessarily incurred by
        the Portfolio Manager as a result of termination and (iii) any outstanding amounts owed by
        the Client under these Terms.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={20}>
      <li className='c26 li-bullet-27'>
        <span className='c18'>Transfer and assignment</span>
      </li>
    </ol>
    <p className='c21'>
      <span className='c1'>
        The obligations under these Terms bind, and the rights will be enforceable by, the parties
        and their respective successors, permitted assigns and personal representatives.
      </span>
    </p>
    <p className='c21 c22'>
      <span className='c1'></span>
    </p>
    <p className='c21'>
      <span className='c1'>
        Subject to the provisions of this paragraph 20, neither the Portfolio Manager nor the Client
        may novate or assign or transfer any of their respective rights or obligations under these
        Terms, any corresponding transaction or any related contract without the prior written
        consent of the other.
      </span>
    </p>
    <p className='c21 c22'>
      <span className='c1'></span>
    </p>
    <p className='c21'>
      <span className='c1'>
        The Portfolio Manager may transfer its rights and/or obligations to any other member of the
        Portfolio Manager&rsquo;s group of companies without the Client&rsquo;s consent, provided
        that the Portfolio Manager gives the Client 20 days&rsquo; notice of such substitution
        (unless that is impracticable in the circumstances). &nbsp;The Client agrees to enter into
        such further documentation and/or particular terms as the transferee may reasonably require
        to effect the transfer.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={21}>
      <li className='c26 li-bullet-14'>
        <span className='c18'>Amendment of General Terms and Conditions</span>
      </li>
    </ol>
    <p className='c49'>
      <span className='c1'>
        The Portfolio Manager reserves the right to amend these General Terms and Conditions at any
        time. Any such amendments shall be communicated to the Client by circular letter or by any
        other appropriate means. Unless an objection is lodged within one month from the date of
        dispatch, such amendments shall be deemed to have been approved and shall replace all
        previous versions.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={22}>
      <li className='c46 c68 li-bullet-28'>
        <span className='c18'>Client complaints</span>
      </li>
    </ol>
    <p className='c20 c34'>
      <span className='c15'>
        Any Client complaint relating to the execution or non-execution of any order, or any dispute
        of a notice or account statement, must be submitted to the Portfolio Manager in writing
        immediately following receipt of the corresponding notice,{' '}
      </span>
      <span className='c24'>
        but no later than 30 days from the date on which the notice was sent
      </span>
      <span className='c1'>
        . If there is no complaint within this period, the measures taken by the Portfolio Manager
        or any non-execution of an order, or the statements prepared by it, shall be deemed to have
        been approved. Any damage resulting from a late complaint shall be borne by the Client.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        If the Client does not receive a notice, it must file its complaint at the time that it
        would normally have received a notice. If the information is made available by way of
        another form or means of communication, the complaint must be made as soon as the notice was
        available for consultation.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        The Portfolio Manager will endeavour to acknowledge any complaint in writing within 5
        Business Days and to provide a full response within 4 weeks of receiving the complaint.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        A copy of the Portfolio Manager&rsquo;s complaints handling procedure is available upon
        request.
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>
        In case the client complaint is not to the satisfaction of the client, the latter can submit
        his grievance to our regulator as per below:
      </span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>Attention: The Chief Executive</span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>The Financial Services Commission</span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>54, Cybercity, Ebene, Republic of Mauritius</span>
    </p>
    <p className='c33 c34'>
      <span className='c1'>Telephone: 230 -404-0200</span>
    </p>
    <p className='c34 c22 c71'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={23}>
      <li className='c17 li-bullet-19'>
        <span className='c18'>Severability</span>
      </li>
    </ol>
    <p className='c8'>
      <span className='c1'>
        Each provision of these Terms is severable and, if at any time, any provision becomes
        invalid, illegal or unenforceable, this will not affect the validity of any of the other
        provisions.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={24}>
      <li className='c17 li-bullet-12'>
        <span className='c18'>Entire agreement and third parties</span>
      </li>
    </ol>
    <p className='c8'>
      <span className='c1'>
        These Terms constitute the entire understanding between the parties relating to the services
        the Portfolio Manager will provide and supersedes all prior understandings, arrangements,
        representations, proposals or communications between the parties, whether written or oral.
        &nbsp;This does not exclude or restrict any duty or liability that the Portfolio Manager may
        have to the Client under Mauritian laws.
      </span>
    </p>
    <p className='c45'>
      <span className='c1'>
        These Terms do not create any right or benefit enforceable by any person or persons not
        party to them, except that the Portfolio Manager&rsquo;s affiliated entities may enforce
        rights as expressed in these Terms.
      </span>
    </p>
    <ol className='c12 lst-kix_list_4-1' start={25}>
      <li className='c17 li-bullet-14'>
        <span className='c18'>Applicable law and jurisdiction</span>
      </li>
    </ol>
    <p className='c49'>
      <span className='c1'>
        All relationships between the Client and the Portfolio Manager shall be governed exclusively
        by the laws of Mauritius and the Mauritius courts shall have exclusive jurisdiction.
      </span>
    </p>
    <p className='c49 c22'>
      <span className='c1'></span>
    </p>
    <p className='c49'>
      <span className='c1'>
        Notwithstanding the foregoing, the Portfolio Manager reserves the right to commence
        proceedings before any other court or competent authority, whether in Mauritius or abroad,
        in particular before the courts in the place of domicile of the Client.
      </span>
    </p>
    <p className='c2'>
      <span className='c1'>These General Terms and Conditions apply with immediate effect.</span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <p className='c2 c22'>
      <span className='c1'></span>
    </p>
    <h1 className='c75'>
      <span className='c1'>PART B: PROVISIONS APPLICABLE TO INVESTMENT SERVICES</span>
    </h1>
    <p className='c87'>
      <span className='c24 c56'>Part B (Section 1) &ndash; Investment Services</span>
    </p>
    <ol className='c12 lst-kix_list_17-0 start' start={1}>
      <li className='c46 c73 li-bullet-29'>
        <span className='c18'>General Terms</span>
      </li>
    </ol>
    <p className='c11'>
      <span className='c1'>
        The Portfolio Manager will provide services unless otherwise agreed.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c11'>
      <span className='c1'>
        An investment mandate or portfolio management agreement or referred to as client agreement
        will be agreed between the Portfolio Manager and the Client for each Portfolio, which will
        specify the basis on which services are to be provided for that Portfolio, including the
        proposed investment strategy, information about the Portfolio Manager&rsquo;s costs and
        associated charges and any restrictions on the range of investments permitted in a
        Portfolio. &nbsp;The Client may hold several Portfolios with the Portfolio Manager.
      </span>
    </p>
    <p className='c11 c22'>
      <span className='c1'></span>
    </p>
    <p className='c11'>
      <span className='c1'>
        The terms set out in this Section 1 apply in all cases (unless otherwise stated) where the
        Portfolio Manager is to provide investment services to the Client under Part B, Sections 2,
        3 and/or 4.
      </span>
    </p>
    <p className='c11 c22'>
      <span className='c1'></span>
    </p>
    <p className='c11 c22'>
      <span className='c1'></span>
    </p>
    <p className='c11'>
      <span className='c1'>
        Information about the risks associated with particular investments or strategies is
        available on request.
      </span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={2}>
      <li className='c46 c73 li-bullet-25'>
        <span className='c18'>Potential conflicts of interest and disclosures</span>
      </li>
    </ol>
    <p className='c11'>
      <span className='c1'>
        The Portfolio Manager and any affiliated entities may effect transactions in which they
        have, directly or indirectly, a material interest or a relationship with another party which
        may involve a potential conflict of interest with the Portfolio Manager&rsquo;s duty to the
        Client. &nbsp;Neither the Portfolio Manager nor any affiliated entity shall be liable to
        account to the Client for any profit, commission or other remuneration made or received from
        or by reason of such transactions, nor will the Portfolio Manager&rsquo;s fees, unless
        otherwise provided, be abated.
      </span>
    </p>
    <p className='c38'>
      <span className='c1'>
        The Portfolio Manager shall ensure that such transactions are effected on terms which are
        not materially less favourable to the Client than if the potential conflict had not existed.
        &nbsp;The Portfolio Manager may manage some conflicts of interest by disclosing such
        interests to the Client.
      </span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={3}>
      <li className='c46 c73 li-bullet-25'>
        <span className='c18'>Valuations, confirmations and periodic statements</span>
      </li>
    </ol>
    <p className='c11'>
      <span className='c1'>
        The Portfolio Manager will send the Client on a quarterly basis, or as may otherwise be
        agreed with the Client, a consolidated reporting pack in relation to each Portfolio which
        may include valuations, transaction statements, cash statements, portfolio performance and
        comparison with any agreed performance benchmark. &nbsp;If the Client so agrees, the
        Portfolio Manager may provide any reports, valuations or statements electronically as
        permitted by the rules of the FSC. &nbsp;While this reporting pack may, if so agreed by the
        Portfolio Manager, include information about assets not held in the Portfolio
        Manager&rsquo;s custody, the official periodic statements in relation to such assets (for
        which the Portfolio Manager does not accept responsibility) will be provided by the relevant
        custodian. &nbsp;The amount of fees and charges incurred during a reporting period,
        including management fees, will be itemised in a separate fee invoice unless the Client has
        requested otherwise.
      </span>
    </p>
    <p className='c38'>
      <span className='c1'>
        Valuations (whether used for ad hoc or periodic statements, or for the calculation of
        potential or incurred taxation) will be based on the most up to date prices available to the
        Portfolio Manager from the sources which it reasonably believes to be reliable. &nbsp;The
        Portfolio Manager will use reasonable endeavours to verify the validity of such data, but
        shall not be liable for any inaccuracies in such data. &nbsp;The Client acknowledges that
        prices shown in any such valuations may not reflect the actual realisable values of
        investments held in the Portfolio. &nbsp;Such valuations will be used for the purpose of
        calculating the Portfolio Manager&rsquo;s fees and commissions in accordance with the
        General Terms and Conditions.
      </span>
    </p>
    <p className='c38'>
      <span className='c1'>
        The consolidated report will include a statement of performance produced in accordance with
        industry standard practice.
      </span>
    </p>
    <p className='c46 c22 c53'>
      <span className='c1'></span>
    </p>
    <p className='c46 c53 c22'>
      <span className='c1'></span>
    </p>
    <p className='c46 c53 c22'>
      <span className='c1'></span>
    </p>
    <p className='c46 c53 c22'>
      <span className='c1'></span>
    </p>
    <p className='c46 c53'>
      <span className='c1'>
        The Portfolio Manager will send the Client or its nominated agent contract notes after it
        executes orders for the Client, unless (i) the Client requests otherwise in writing, (ii) it
        would duplicate the information that another firm is providing to the Client or (iii) the
        Portfolio Manager is managing the Client&rsquo;s Portfolio on a discretionary basis.
        &nbsp;If the Client agrees, the Portfolio Manager may provide such contract notes using
        electronic means as permitted under the laws of Mauritius. &nbsp;If the Portfolio Manager is
        managing the Portfolio on a discretionary basis, the Client may elect to receive information
        about executed orders on a transaction-by-transaction basis by notifying the Portfolio
        Manager in writing.
      </span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={4}>
      <li className='c46 c44 li-bullet-30'>
        <span className='c18'>Voting</span>
      </li>
    </ol>
    <p className='c4'>
      <span className='c1'>
        Where the Portfolio Manager manages the Client&rsquo;s Portfolio on a discretionary basis,
        the Portfolio Manager may procure the exercise of any voting rights attaching to holdings in
        such Portfolio as it sees fit.
      </span>
    </p>
    <p className='c11 c22 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        The Portfolio Manager will only procure the exercise of any voting rights attaching to
        holdings in (i) an advisory or execution-only Portfolio or (ii) an in-house collective
        investment scheme or connected investment trust on the Client&rsquo;s specific instructions,
        save that the Portfolio Manager may exercise voting rights in relation to in-house
        collective investment schemes for any administrative matter or other change to the scheme
        that does not involve a conflict of interest for the Portfolio Manager or an affiliated
        entity. &nbsp;The Portfolio Manager may count holdings in an in-house collective investment
        scheme or connected investment trust for the purpose of constituting a quorum at a general
        meeting of any such scheme or trust.
      </span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={5}>
      <li className='c46 c44 li-bullet-24'>
        <span className='c18'>Dealing and counterparties</span>
      </li>
    </ol>
    <p className='c4'>
      <span className='c1'>
        The Portfolio Manager will act in good faith and with due diligence in its choice and use of
        counterparties.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        In executing orders in relation to a Portfolio, the Portfolio Manager will seek best
        execution at all times and may (subject to the investment mandate for the Portfolio) deal on
        such markets or exchanges and with such counterparties as it thinks fit. &nbsp;All
        transactions will be effected in accordance with the rules and regulations of the relevant
        market or exchange, and the Portfolio Manager may take all such steps as may be required or
        permitted by such rules and regulations and/or by appropriate market practice.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        If any counterparty fails to deliver any necessary documents or to complete any transaction,
        the Portfolio Manager will take all reasonable steps on the Client&rsquo;s behalf to rectify
        such failure or obtain compensation in lieu thereof. &nbsp;The Client shall pay all
        resulting reasonable costs and expenses properly incurred by the Portfolio Manager.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        The Portfolio Manager may aggregate transactions for the Client with those of other clients
        and of its employees and affiliated entities and will allocate such transactions on a fair
        and reasonable basis in accordance with the requirements of its relevant Licences. &nbsp;The
        Client recognises that each individual aggregated transaction may operate to its advantage
        or disadvantage.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        Where the Portfolio Manager has the authority to effect transactions or take steps on the
        Client&rsquo;s behalf, the Portfolio Manager may agree such reasonable terms as it thinks
        fit with the counterparty or other person involved (which may be an affiliated entity) and
        for that purpose the Portfolio Manager may:
      </span>
    </p>
    <ol className='c12 lst-kix_list_18-0 start' start={1}>
      <li className='c4 c100 li-bullet-31'>
        <span className='c1'>
          give representations and warranties on the Client&rsquo;s behalf;
        </span>
      </li>
      <li className='c4 c100 li-bullet-32'>
        <span className='c1'>
          execute agreements, confirmations, terms of business, master documentation and enter into
          other contractual arrangements on the Client&rsquo;s behalf; and
        </span>
      </li>
      <li className='c4 c100 li-bullet-33'>
        <span className='c1'>
          take any steps in accordance with market practice or custom as the Portfolio Manager
          thinks fit for the purpose of effecting or settling those transactions, and all such
          matters will be binding on the Client.
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_17-0' start={6}>
      <li className='c46 c44 li-bullet-34'>
        <span className='c18'>
          Information about the Portfolio Manager&rsquo;s execution policy
        </span>
      </li>
    </ol>
    <p className='c4'>
      <span className='c1'>
        The Portfolio Manager has put in place an order execution policy and will take all
        reasonable steps to obtain the best possible result on behalf of the Client when executing
        orders in relation to securities and derivatives or receiving and transmitting such orders
        for execution. &nbsp;
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        Where the Portfolio Manager makes any material changes to its order execution arrangements
        or execution policy, it will notify the Client of such changes, which may be by means of the
        Portfolio Manager&rsquo;s website or by such other means as the Portfolio Manager determines
        is suitable.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c15'>
        [The Portfolio Manager will execute most structured products and derivatives for Clients as
        principal. &nbsp;Where it does so, this will be stated in the transaction confirmation.
        &nbsp;When the Portfolio Manager executes structured products and over-the-counter (&ldquo;
      </span>
      <span className='c24'>OTC</span>
      <span className='c1'>
        &rdquo;) derivatives, the best execution obligation technically applies even where the
        Portfolio Manager deals as principal but has limited scope.]
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c25 c102'>
      <span className='c15'>[For foreign exchange (&ldquo;</span>
      <span className='c24'>FX</span>
      <span className='c1'>
        &rdquo;) orders in major currencies, the Portfolio Manager will buy/sell the currency
        required from/to the Client at the prevailing market rate (subject to a bid/offer spread)
        and apply a tiered margin to such trades. &nbsp;FX orders are generally executed by as at
        rates fixed at least three times a day from independent price sources with tiered margins
        applied. &nbsp;For FX transactions involving currencies that the Portfolio Manager does not
        execute as principal or which are executed by a third party broker in connection with a
        security transaction, the currency is bought/sold by the broker at the prevailing market
        rates with a margin or bid/offer spread applied.]
      </span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={7}>
      <li className='c46 c44 li-bullet-35'>
        <span className='c18'>Warrants, derivatives </span>
      </li>
    </ol>
    <p className='c4'>
      <span className='c1'>
        The Portfolio Manager will not effect transactions in warrants or derivatives (including
        contingent liability investments) unless specifically agreed in the investment mandate or
        otherwise with the Client. &nbsp;There are inherent risks associated with such transactions,
        details of which can be provided by the Portfolio Manager on request.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        The Client acknowledges and recognises that the markets for options, futures or contracts
        for differences can be highly volatile and such investments carry a high risk of loss.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        The Client also acknowledges that margin payments payable pursuant to contingent liability
        investments might take the form of a deposit of cash or investments as security for
        unrealised losses which have occurred or may occur in relation to the Client&rsquo;s
        investments. &nbsp;Payment may be required both on entering into a transaction and on a
        daily basis throughout the life of the transaction if the value of transaction moves against
        the Client. &nbsp;The movement in the market price of the investment will therefore affect
        the amount of margin payment the Client will be required to make.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c4'>
      <span className='c1'>
        If the Client fails to provide margin when required to do so, the Portfolio Manager (or any
        applicable exchange, clearing house or counterparty) may close out the Client&rsquo;s
        positions and exercise any of the rights described in these Terms. &nbsp;If margin is not
        provided, the Portfolio Manager will normally close out that position within five Business
        Days following the date on which the obligation to meet the margin accrues.
      </span>
    </p>
    <p className='c6 c14'>
      <span className='c1'></span>
    </p>
    <p className='c6 c102'>
      <span className='c9'></span>
    </p>
    <p className='c6 c14'>
      <span className='c9'></span>
    </p>
    <p className='c6 c14'>
      <span className='c9'></span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={8}>
      <li className='c30 c48 li-bullet-36'>
        <span className='c1'>as a </span>
        <span className='c18'>Investments which are not readily realisable</span>
      </li>
    </ol>
    <p className='c25 c47'>
      <span className='c1'>
        In accordance with the terms of the investment services which are provided, the Portfolio
        Manager may invest in investments which are not readily realisable investments in which
        there is a restricted market. &nbsp;The Client hereby acknowledges that it may therefore be
        difficult to deal in such investments or to obtain reliable information about their value.
      </span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={9}>
      <li className='c30 c48 li-bullet-37'>
        <span className='c18'>Securities subject to stabilisation</span>
      </li>
    </ol>
    <p className='c25 c47'>
      <span className='c1'>
        The Portfolio Manager or its representatives may, from time to time, recommend to the Client
        transactions in securities, or carry out such transactions on the Client&rsquo;s behalf,
        where the price may have been influenced by measures taken to stabilise it. &nbsp;If this is
        the case the Portfolio Manager will inform the Client in advance that such stabilisation has
        taken place. &nbsp;Further information on the risks relating to this can be provided on
        request.
      </span>
    </p>
    <p className='c6 c47'>
      <span className='c1'></span>
    </p>
    <p className='c6 c47'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={10}>
      <li className='c46 c113 li-bullet-38'>
        <span className='c18'>Off-exchange transactions</span>
      </li>
    </ol>
    <p className='c25 c102'>
      <span className='c1'>
        Where the Portfolio Manager reasonably believes that such course of action is in the
        Client&rsquo;s best interests, the Client agrees that the Portfolio Manager may execute
        orders on its behalf outside a regulated market or multilateral trading facility or
        otherwise in circumstances in which the relevant transaction is not regulated by the rules
        of any stock exchange or investment exchange and the Portfolio Manager may deal for the
        Client on an exchange which has not yet been recognised or designated by the FSC. &nbsp;The
        Client should be aware that not consenting to such methods of executing orders may adversely
        affect the Portfolio Manager&rsquo;s ability to obtain the best possible results for the
        Client, particularly in relation to fixed income instruments or other financial instruments
        in which there is significant OTC liquidity.
      </span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={11}>
      <li className='c46 c44 li-bullet-39'>
        <span className='c18'>Gearing</span>
      </li>
    </ol>
    <p className='c25 c102'>
      <span className='c1'>
        Where the Portfolio Manager reasonably believes that such course of action is in the
        Client&rsquo;s best interests, the Portfolio Manager may recommend to the Client or deal for
        it in securities which may use gearing. &nbsp;The strategy which the issuer of such
        securities uses or proposes to use may result in movements in the price of the securities
        being more volatile than the price of underlying investments and being subject to sudden and
        large falls in value.
      </span>
    </p>
    <p className='c46 c22'>
      <span className='c1'></span>
    </p>
    <p className='c87'>
      <span className='c56 c24'>Part B (Section 2) &ndash; Discretionary Management Terms</span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={12}>
      <li className='c46 c44 li-bullet-39'>
        <span className='c18'>Discretionary mandate</span>
      </li>
    </ol>
    <p className='c25'>
      <span className='c1'>
        The Portfolio Manager will manage any specified Portfolio in accordance with the investment
        objectives and subject to any restrictions stated in the investment mandate(s) and will act
        in good faith and with due diligence. &nbsp;Subject to such objectives and restrictions, the
        Portfolio Manager, normally acting as agent, will have complete discretion over the relevant
        Portfolio (without prior reference to the Client) to buy, sell, retain, exchange or
        otherwise deal in investments and other assets, place deposits, subscribe to issues and
        offers for sale and accept placings, underwritings and sub-underwritings of any investments,
        make decisions in relation to corporate actions and class actions or execute transactions in
        collective investment schemes and otherwise act as the Portfolio Manager judges appropriate
        in relation to the management of a Portfolio.
      </span>
    </p>
    <p className='c46'>
      <span className='c1'>
        The investment objectives and restrictions stated in the investment mandate(s) will not be
        deemed to be breached as a result of changes in the price or value of assets in a Portfolio
        brought about solely through movements in the market.
      </span>
    </p>
    <p className='c46'>
      <span className='c1'>
        The Portfolio Manager will keep the objectives and restrictions stated in the investment
        mandate(s) under review and may, from time to time, suggest to the Client such amendments
        as, in the Portfolio Manager&rsquo;s opinion, are appropriate. &nbsp;The Client hereby
        agrees to advise the Portfolio Manager of any changes in circumstances which may alter or
        affect the relevance or suitability of the terms of the investment mandate(s).
      </span>
    </p>
    <p className='c46'>
      <span className='c1'>
        Where a discretionary management agreement is in place (or contemplated), any communication
        with the Client in relation to investments does not create an advisory relationship between
        the Client and the Portfolio Manager, but is made with the agreed understanding and on the
        basis that any subsequent investment transaction shall be made by the Portfolio Manager as a
        discretionary investment management decision. &nbsp;The Portfolio Manager may, however, from
        time to time, provide the Client with general information, including information about
        investments and markets, general opinions on investments or markets, guidance about the
        risks associated with any investments or financial promotions about investments. &nbsp;Any
        such communication is not, and is not intended as, a personal recommendation to the Client
        and should not be treated as such, and any investment transaction that is completed
        following such communication will be undertaken by the Portfolio Manager in the exercise of
        discretionary management authority that the Client has given it. &nbsp;If the Client wishes
        to receive personal recommendations from the Portfolio Manager, the parties will need to
        agree an investment advisory mandate in accordance with Section 3 of this Part B.
      </span>
    </p>
    <p className='c30 c22'>
      <span className='c1'></span>
    </p>
    <p className='c22 c30'>
      <span className='c1'></span>
    </p>
    <p className='c0'>
      <span className='c56 c24'>Part B (Section 3) &ndash; Investment Advisory Terms</span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={13}>
      <li className='c27 li-bullet-40'>
        <span className='c18'>Mandate</span>
      </li>
    </ol>
    <p className='c25'>
      <span className='c1'>
        The Portfolio Manager may advise the Client on, and execute orders in, investments and
        external deposits, where agreed on the Client&rsquo;s behalf (subject to the principles of
        best execution) but, in each case, only upon the Client&rsquo;s specific authorisation and
        not in the exercise of the Portfolio Manager&rsquo;s discretion. &nbsp;The Portfolio Manager
        may from time to time give the Client advice on, or review, its investments or Portfolio(s)
        on an unsolicited basis, but the Client accepts that it will specifically authorise all
        dealings.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        The Portfolio Manager agrees to act as non-discretionary investment Portfolio Manager for
        the Client (unless the Portfolio Manager notifies the Client otherwise) and, where it acts
        as such, will provide the Client with ongoing advice on the suitability of investments in
        the Portfolio and, on at least a quarterly basis, will review the Portfolio against the
        investment mandate. &nbsp;In giving investment advice, the Portfolio Manager may advise the
        Client in connection with a Portfolio and such other matters as it deems appropriate.
        &nbsp;On the Client&rsquo;s request, the Portfolio Manager shall express reasons influencing
        such advice but, subject thereto, it is not obliged to state the basis on which the
        judgement leading to any such advice is founded.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        The investment objective of the Portfolio is as set out in the investment mandate. &nbsp;The
        Client acknowledges that the performance of a Portfolio may vary significantly from the
        investment objectives as a result of decisions made by the Client.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        Under an investment &nbsp;service, the Portfolio Manager will provide the Client with
        ongoing investment advice in relation to its Portfolio. &nbsp;The ongoing service includes
        portfolio monitoring, initiating portfolio changes and an annual review. &nbsp;In such
        cases, the Portfolio Manager acts as non-discretionary investment Portfolio Manager and will
        charge an ongoing quarterly fee at the rate set out in its tariff of charges or the
        investment mandate agreed in accordance with the General Terms of Business. &nbsp;The Client
        is free to cancel this service at any time without any penalty. &nbsp;If the Client does not
        require an ongoing advisory service, it can elect to receive advice on a
        transaction-by-transaction basis. &nbsp;The fees chargeable for this service will be agreed
        with the Client in advance. &nbsp;If the Client cancels any such service, the Portfolio
        Manager will not undertake any portfolio monitoring or periodic reviews subsequently and the
        Client will be responsible for reviewing its Portfolio and for contacting the Portfolio
        Manager if it requires advice or wishes to instruct the Portfolio Manager to execute a
        transaction.
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        Generally the Portfolio Manager does not give advice on insurance-linked or protection
        products, and so, when advising the Client, will not take into account the Client&rsquo;s
        insurance or protection needs. &nbsp;
      </span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c10'>
      <span className='c56 c24'>Part B (Section 4) &ndash; Execution-Only Terms</span>
    </p>
    <ol className='c12 lst-kix_list_17-0' start={14}>
      <li className='c46 c44 li-bullet-29'>
        <span className='c18'>Mandate</span>
      </li>
    </ol>
    <p className='c46'>
      <span className='c1'>
        The Portfolio Manager has no discretion in respect of a Portfolio and will effect a
        transaction only on the Client&rsquo;s specific instructions.
      </span>
    </p>
    <p className='c46'>
      <span className='c1'>
        The Portfolio Manager will assume that, as a professional client, the Client has the
        necessary experience and knowledge to understand the risks involved in the relevant
        transaction or service. &nbsp;It will normally assess appropriateness when it is executing
        orders or receiving and transmitting orders for the Client on the basis of a recommendation
        made by another investment firm.
      </span>
    </p>
    <p className='c46'>
      <span className='c1'>
        Under an execution-only service, the Portfolio Manager will not provide the Client with any
        investment advice. &nbsp;However, it may, from time to time, provide the Client with general
        information about investments, including information about investments and markets, general
        opinions on investments or markets, guidance about the risks associated with any investments
        or financial promotions about investments. &nbsp;
      </span>
    </p>
    <p className='c46 c22'>
      <span className='c1'></span>
    </p>
    <p className='c46'>
      <span className='c1'>
        Any such communication is not, and is not intended as, a personal recommendation to the
        Client and should not be treated as such, and any investment transaction that is completed
        following such communication will be undertaken by the Portfolio Manager in the exercise of
        discretionary management authority that the Client has given it. &nbsp;If the Client wishes
        to receive personal recommendations from the Portfolio Manager, the parties will need to
        agree an investment advisory mandate in accordance with Section 3 of this Part B.
      </span>
    </p>
    <p className='c46'>
      <span className='c1'>
        Please refer to the General Terms and Conditions regarding sending the Portfolio Manager
        instructions via e-mail, limit orders and stop loss orders.
      </span>
    </p>
    <p className='c6 c47'>
      <span className='c1'></span>
    </p>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <h1 className='c75'>
      <span className='c1'>PART C: PROVISIONS APPLICABLE TOARRANGING CUSTODY SERVICES</span>
    </h1>
    <h1 className='c61'>
      <span className='c15'>
        Where the Portfolio Manager arranges/provides for custody services via the{' '}
      </span>
      <span className='c24'>Custodian</span>
      <span className='c1'>
        , these provisions govern the contractual relationship between the Portfolio Manager and the
        Client subject to any specific agreements between the parties.
      </span>
    </h1>
    <p className='c108 c112'>
      <span className='c15'>
        Any terms capitalised but not defined in these provisions applicable to custody (&ldquo;
      </span>
      <span className='c24'>Custody Provisions</span>
      <span className='c1'>
        &rdquo;) shall have the meaning set out in these Terms more generally.
      </span>
    </p>
    <p className='c22 c72'>
      <span className='c18'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0 start' start={1}>
      <li className='c37 c44 li-bullet-41'>
        <span className='c1'>
          The Portfolio Manager undertakes to hold, via the Custodian, all classes of securities and
          any investments not represented by securities (in particular, any deposit of money market
          instruments and capital and securities for which certificates have not yet been issued)
          (&ldquo;
        </span>
        <span className='c18'>Securities</span>
        <span className='c1'>
          &rdquo;) in a designated client custody and administration account (&ldquo;
        </span>
        <span className='c18'>Account</span>
        <span className='c1'>
          &rdquo;). The concept of &ldquo;Securities&rdquo; shall apply by analogy to securities
          within the meaning of the Mauritius Securities Act 2005, which can be amended from time to
          time.
        </span>
      </li>
    </ol>
    <p className='c37 c22'>
      <span className='c1'></span>
    </p>
    <p className='c37'>
      <span className='c1'>
        The Portfolio Manager, via the Custodian, may refuse to hold all or part of the assets in
        the Account, without giving any reason.
      </span>
    </p>
    <p className='c37 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={2}>
      <li className='c37 c44 li-bullet-24'>
        <span className='c1'>
          At least twice per calendar year, the Portfolio Manager shall provide the Client with a
          statement of Securities on deposit. &nbsp;The valuation of assets on deposits shall be
          provided for information purposes only and shall not be binding upon the Portfolio
          Manager.
        </span>
      </li>
    </ol>
    <p className='c37 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={3}>
      <li className='c37 c44 li-bullet-42'>
        <span className='c1'>
          The Portfolio Manager, via the Custodian, shall keep in a safe location the deposited
          assets entrusted to it. It shall be authorised to have the deposited securities held in
          its or the Custodian&rsquo;s name with correspondents in its country of incorporation or
          abroad with third parties or parties in its same group of companies, but for the Account
          and at the risk of the Client. &nbsp;In particular, the Client expressly authorises the
          Portfolio Manager to call upon a correspondent outside Mauritius which is not subject to
          the same prudential supervision as the Portfolio Manager. &nbsp;The Portfolio Manager, via
          the Custodian, reserves the right to deposit the securities in a collective account
          internally or with a central collective custodian. &nbsp;If the collective custody account
          is located in Mauritius, the Client becomes a joint owner of the collective custody
          account or accounts in proportion to his or her share of the assets. &nbsp;If the
          collective account is located abroad, the deposited assets shall be subject to the laws
          and customs of the place where they are held in safe-keeping. &nbsp;If any foreign law
          renders the return of the assets or the proceeds of their sale difficult or even
          impossible, the Portfolio Manager shall only be responsible for obtaining the right to the
          return of the assets or the corresponding proceeds for the Client if that right exists and
          is transferable. The Client is on notice that the Client&rsquo;s Safe Custody investments
          may be held in a jurisdiction outside Mauritius and the market practices, insolvency and
          legal regime may be different to that which applies in Mauritius
        </span>
      </li>
    </ol>
    <p className='c54 c22'>
      <span className='c1'></span>
    </p>
    <p className='c37'>
      <span className='c1'>
        Securities in the Account are subject to the laws and customs in effect at the place where
        they are held. &nbsp;Securities which are the subject of a drawing of lots may also be held
        in collective custody accounts. &nbsp;The Portfolio Manager, via the Custodian, shall divide
        these assets among Clients in an equal manner, in proportion to their positions.
      </span>
    </p>
    <p className='c54 c22'>
      <span className='c1'></span>
    </p>
    <p className='c37 c80'>
      <span className='c1'>
        Under no circumstances is the Portfolio Manager responsible for any consequences arising
        from a sub-Custodian selected by the Client against the Portfolio Manager&rsquo;s
        recommendation.
      </span>
    </p>
    <p className='c37 c80 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={4}>
      <li className='c35 li-bullet-35'>
        <span className='c1'>
          In respect of securities that are entrusted to it in the Account, the Portfolio Manager
          shall, via the Custodian, automatically and without assuming any responsibility for any
          errors or omissions, conduct any daily business operations required. Such operations
          include, in particular, the following:
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_7-1 start' start={1}>
      <li className='c36 li-bullet-35'>
        <span className='c1'>
          monitoring of coupon payment notices, lists of drawing of lots, notices relating to
          redemption, repayment, conversion, subscription rights, etc.;
        </span>
      </li>
      <li className='c36 li-bullet-34'>
        <span className='c1'>
          cutting off and cashing or negotiation of maturing interest and dividend coupons;
        </span>
      </li>
      <li className='c36 li-bullet-39'>
        <span className='c1'>
          renewal of coupon sheets and exchange of temporary certificates for actual securities; and
        </span>
      </li>
      <li className='c36 li-bullet-24'>
        <span className='c1'>cashing of redeemable securities.</span>
      </li>
    </ol>
    <p className='c37 c80 c97 c22'>
      <span className='c1'></span>
    </p>
    <p className='c37 c80 c22 c97'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={5}>
      <li className='c35 li-bullet-39'>
        <span className='c1'>
          On written instructions from the Client given in a timely manner (in the absence of
          agreement to the contrary), the Portfolio Manager will, via the Custodian, take care of:
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_7-1 start' start={1}>
      <li className='c36 li-bullet-43'>
        <span className='c1'>enforcement of rights of conversion or option rights; and/or</span>
      </li>
      <li className='c36 li-bullet-30'>
        <span className='c1'>
          the exercise or sale of subscription rights. &nbsp;If instructions from the Client are not
          received by the Portfolio Manager in time, the Portfolio Manager shall have the right, but
          shall not be obliged, to exercise or sell the subscription right in its discretion.
        </span>
      </li>
    </ol>
    <p className='c31 c22'>
      <span className='c1'></span>
    </p>
    <p className='c42 c34'>
      <span className='c1'>
        As a general rule, the Portfolio Manager shall, via the Custodian, subscribe to capital
        increases for the account of its Client insofar as it has available funds. &nbsp;It reserves
        the right not to do this.
      </span>
    </p>
    <p className='c54 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={6}>
      <li className='c25 c44 li-bullet-24'>
        <span className='c1'>
          Whenever the printing of securities is deferred, the Portfolio Manager shall, via the
          Custodian, be authorised to:
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_7-1 start' start={1}>
      <li className='c3 li-bullet-24'>
        <span className='c1'>
          have existing securities converted by the issuer into uncertificated securities;
        </span>
      </li>
      <li className='c3 li-bullet-39'>
        <span className='c1'>
          carry out normal administrative tasks, give the issuer the necessary instructions, obtain
          any indispensable information;
        </span>
      </li>
      <li className='c3 li-bullet-25'>
        <span className='c1'>
          demand printing of securities by the issuer for the account of the Client and have such
          securities delivered.
        </span>
      </li>
    </ol>
    <p className='c54 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={7}>
      <li className='c37 c44 c34 li-bullet-39'>
        <span className='c1'>
          The Client may only exercise its rights pertaining to intermediated securities via the
          Portfolio Manager. In particular the Portfolio Manager shall, via the Custodian, only
          exercise the voting rights attached to securities held in custody on the basis of written
          instructions from the Client.
        </span>
      </li>
    </ol>
    <p className='c31 c22'>
      <span className='c1'></span>
    </p>
    <p className='c34 c42'>
      <span className='c1'>
        Unless specifically requested to do so by the Client, the Portfolio Manager shall not be
        responsible for informing the Client about the existence of composition proceedings,
        insolvency proceedings, actions commenced by any group of shareholders (so-called
        &ldquo;class actions&rdquo;), or any other similar proceedings relating to the securities
        deposited. &nbsp;Any legal proceedings to be undertaken within the framework of these
        actions to protect the rights associated with the deposited securities must be commenced by
        the Client directly and at the Client&rsquo;s expense.
      </span>
    </p>
    <p className='c22 c54'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={8}>
      <li className='c37 c44 c34 li-bullet-30'>
        <span className='c1'>
          If it is unusual or impossible to have the securities deposited in the name of the Client
          registered, the Portfolio Manager may have the deposited securities registered in the name
          of the Custodian, its name or the name of a third party agent, but always for the Account
          and solely at the risk of the Client. &nbsp;If registered securities, once issued, are
          registered on a fiduciary basis in the name of the Custodian, the Portfolio Manager or a
          third party designated by the Portfolio Manager for this purpose, the Portfolio Manager
          may demand that these securities remain in the Account. &nbsp;In no circumstances can the
          Portfolio Manager or the Custodian be compelled to act or to become a party to a civil,
          criminal or administrative suit against the issuer of the securities or any intervening
          third party.
        </span>
      </li>
    </ol>
    <p className='c22 c31'>
      <span className='c1'></span>
    </p>
    <p className='c42 c34'>
      <span className='c1'>
        The Client is solely responsible for taking whichever measures it deems adequate, and hereby
        expressly acknowledges that it could, in certain circumstances and due to the fact that the
        securities are registered in the name of the Custodian or the Portfolio Manager, lose all
        right of action against the issuer of the securities or any intervening third party,
        particularly in the event of the total or partial unassignability of securities or debt.
      </span>
    </p>
    <p className='c42 c34 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={9}>
      <li className='c37 c44 c34 li-bullet-29'>
        <span className='c1'>
          Subject to compliance with contractual deadlines, legal provisions and any rights of
          pledge or retention, the Client may dispose of all of its assets at the time and agreed in
          advance with the Portfolio Manager. For technical and administrative reasons, the
          Portfolio Manager must receive sufficient advance notice before any major withdrawal is
          made, or if the withdrawal involves securities deposited with third parties or abroad, in
          order to be able to perform, or ensure that the Custodian performs, the required
          operations at the appropriate time. &nbsp;If the Client requests the return of his or her
          assets at a place other than the place of performance, it shall assume the risks and
          expenses thereof. &nbsp;At the request of the Client, the Portfolio Manager will provide
          or have delivered the securities corresponding in number and type to the intermediated
          securities credited to the Client&rsquo;s Account:
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_7-1 start' start={1}>
      <li className='c37 c40 c34 li-bullet-44'>
        <span className='c1'>
          if the corresponding securities are kept by the Custodian, the Portfolio Manager or one of
          its sub-custodians; or
        </span>
      </li>
      <li className='c37 c34 c40 li-bullet-45'>
        <span className='c1'>
          if the Client is entitled to issue securities according to the issuers&rsquo; articles of
          association and conditions of issuance.
        </span>
      </li>
    </ol>
    <p className='c6'>
      <span className='c1'></span>
    </p>
    <p className='c25'>
      <span className='c1'>
        The securities provided conform to the norms of the market where they are traded.
      </span>
    </p>
    <p className='c22 c51'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={10}>
      <li className='c25 c44 li-bullet-24'>
        <span className='c1'>
          Subject to any provision stating otherwise, when several persons hold a joint custody
          account, the right of disposition over the same may only be exercised by all of the joint
          custody account holders. The joint custody account holders shall be jointly and severally
          liable to the Portfolio Manager for all of the commitments resulting from the custody
          account.
        </span>
      </li>
    </ol>
    <p className='c11 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={11}>
      <li className='c25 c44 li-bullet-29'>
        <span className='c1'>
          In providing custody services, the Portfolio Manager shall, or shall use reasonable
          endeavours to ensure that the Custodian shall:
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_7-1 start' start={1}>
      <li className='c3 li-bullet-35'>
        <span className='c1'>
          maintain the Account to which all income and proceeds derived from the Securities shall be
          credited;
        </span>
      </li>
      <li className='c3 li-bullet-39'>
        <span className='c1'>
          maintain records showing the accounts belong to or are under the duly authorised control
          of the Client;
        </span>
      </li>
      <li className='c3 li-bullet-34'>
        <span className='c1'>
          keep Securities belonging to the Client segregated from assets held by the Custodian or
          the Portfolio Manager for its own account;
        </span>
      </li>
      <li className='c3 li-bullet-25'>
        <span className='c1'>
          ensure that all of the Client&rsquo;s Securities held by it, its nominees or agents or the
          Custodian are clearly recorded in the books of the Portfolio Manager as belonging to the
          Client and not to the Portfolio Manager or other customers, agents or nominees of the
          Portfolio Manager and the Client acknowledges that the Portfolio Manager is not liable for
          the default of the third party agent or the Custodian;
        </span>
      </li>
      <li className='c3 li-bullet-35'>
        <span className='c1'>
          not part with possession of the Securities except to the Client or on the specific
          instructions of the Client;
        </span>
      </li>
      <li className='c3 li-bullet-39'>
        <span className='c1'>
          maintain such records as are necessary to identify the location of each title document,
          subject to prior agreement with the Client including details of:
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_7-2 start' start={1}>
      <li className='c25 c59 li-bullet-39'>
        <span className='c1'>
          the date at which the title document came into or left the possession or control of the
          Portfolio Manager;
        </span>
      </li>
      <li className='c25 c59 li-bullet-34'>
        <span className='c1'>the nature of the title document; and</span>
      </li>
      <li className='c25 c59 li-bullet-29'>
        <span className='c1'>
          the nature, amount and nominal value of the investment to which the title document
          relates;
        </span>
      </li>
    </ol>
    <ol className='c12 lst-kix_list_7-1' start={7}>
      <li className='c3 li-bullet-24'>
        <span className='c1'>
          advise the Client in writing, in accordance with such procedures and in a form as may be
          mutually agreed upon with the Client, regarding the disbursement of all moneys, the
          receipt of all Securities, stock and cash dividends, interest and other income, the
          receipt from all exchanges of Securities and shall in general keep the Client fully
          informed as to all other material actions taken by the Portfolio Manager under this
          Agreement;
        </span>
      </li>
      <li className='c3 li-bullet-29'>
        <span className='c1'>
          except when this obligation is expressly limited, be responsible to promptly transmit
          and/or notify to the Client all material information including but not limited to notices,
          reports and other financial information relating to the Securities received by the
          Portfolio Manager from issuers of the Securities and shall seek instructions from the
          Client as to the action to be taken in this regard;
        </span>
      </li>
      <li className='c3 li-bullet-24'>
        <span className='c1'>
          in case of any other dealing in connection with the Securities, take such other actions as
          may be required in relation thereto consistent with the rights of the Client and in the
          best interests of the Client;
        </span>
      </li>
      <li className='c3 li-bullet-29'>
        <span className='c1'>
          ensure safe physical custody of the Securities held by it physically on behalf of the
          Client;
        </span>
      </li>
      <li className='c3 li-bullet-24'>
        <span className='c1'>
          be responsible for the activities performed by any of its officers/employees/appointees
          and also to supervise their operations;
        </span>
      </li>
      <li className='c3 li-bullet-30'>
        <span className='c1'>
          carry out any additional duties as may from time to time be mutually agreed in writing
          with the Client; and
        </span>
      </li>
      <li className='c3 li-bullet-46'>
        <span className='c1'>
          exercise the same degree of care as it exercises in respect of its own property whilst
          performing the above duties.
        </span>
      </li>
    </ol>
    <p className='c54 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={12}>
      <li className='c42 c44 li-bullet-47'>
        <span className='c1'>
          The Portfolio Manager is authorised to take such steps as it may consider expedient to
          enable it to provide the services and to exercise its powers under this Agreement,
          including the right:
        </span>
      </li>
    </ol>
    <p className='c42 c22'>
      <span className='c1'></span>
    </p>
    <p className='c42 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-1' start={14}>
      <li className='c42 c83 li-bullet-39'>
        <span className='c1'>
          to comply with any applicable law, regulation, order, directive, notice or request of any
          government agency (whether or not having the force of law) requiring the Portfolio Manager
          to take or refrain from action and/or to disclose information in agreement with the
          Client;
        </span>
      </li>
      <li className='c42 c83 li-bullet-25'>
        <span className='c1'>
          on behalf of the Client, to withhold and/or to make payment of any taxes or duties payable
          on or in respect of Securities by debiting the Accounts after due intimation and after
          receipt of instructions from the Client; and
        </span>
      </li>
      <li className='c42 c83 li-bullet-24'>
        <span className='c1'>
          in the absence of or in the event of any delay in receiving instructions from the Client
          in response to a request, to act or refrain from acting as it may deem expedient in the
          best interests of the Client.
        </span>
      </li>
    </ol>
    <p className='c42 c22'>
      <span className='c1'></span>
    </p>
    <p className='c42 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={13}>
      <li className='c42 c44 li-bullet-39'>
        <span className='c1'>
          The Portfolio Manager shall be under no duty to, or to ensure that the Custodian does,
          investigate, participate in or take affirmative action concerning proxies received,
          attendance at meetings and voting except in accordance with the instructions of the
          Client. &nbsp;In the absence of such instructions, the Portfolio Manager shall take no
          action unless otherwise required under law.
        </span>
      </li>
    </ol>
    <p className='c42 c22'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={14}>
      <li className='c42 c44 li-bullet-39'>
        <span className='c1'>Instructions</span>
      </li>
    </ol>
    <p className='c42 c22'>
      <span className='c1'></span>
    </p>
    <p className='c42'>
      <span className='c1'>Transactions Not Requiring Instructions</span>
    </p>
    <p className='c107 c22'>
      <span className='c1'></span>
    </p>
    <p className='c107'>
      <span className='c1'>
        In the absence of contrary instructions and so long as the Portfolio Manager is prepared to
        provide the services (in case the Portfolio Manager refuses to provide the services if in
        its opinion there are reasonable grounds for such refusal, then the Portfolio Manager will
        notify the Client accordingly), the Portfolio Manager is authorised by the Client to carry
        out, via the Custodian, administrative acts and/or the following transactions relating to
        the Securities without requiring further instructions from the Client:
      </span>
    </p>
    <ol className='c12 lst-kix_list_8-0 start' start={1}>
      <li className='c5 li-bullet-39'>
        <span className='c1'>
          to complete and sign any affidavits, certificates of ownership or other certificates
          relating to the Securities in connection with the Portfolio Manager&rsquo;s duties under
          this Agreement which may be required by the tax or any other regulatory authority;
        </span>
      </li>
      <li className='c60 c83 c57 li-bullet-42'>
        <span className='c1'>
          to collect and receive, for the account of the Client, all income and other payments and
          distributions in respect of the Securities, and credit the same to the relevant account;
        </span>
      </li>
      <li className='c23 li-bullet-25'>
        <span className='c1'>
          to receive and hold for the account of the Client any capital arising out of or in
          connection with the Cash whether as a result of its being called or redeemed or otherwise
          becoming payable and credit the same to the relevant account;
        </span>
      </li>
      <li className='c23 li-bullet-35'>
        <span className='c1'>
          to receive and hold for the account of the Client all Securities received by the Portfolio
          Manager as a result of a stock dividend, share sub-division or reorganisation,
          capitalisation of reserves or otherwise;
        </span>
      </li>
      <li className='c29 li-bullet-34'>
        <span className='c1'>
          to exchange interim or temporary receipts for definitive certificates, and old or
          overstamped certificates for new certificates;
        </span>
      </li>
      <li className='c29 li-bullet-25'>
        <span className='c1'>
          to make cash disbursements or payments for any fees, taxes, duties, levies, expenses
          and/or any payments incurred in connection with the Portfolio Manager&rsquo;s duties under
          this Agreement; and
        </span>
      </li>
      <li className='c23 li-bullet-39'>
        <span className='c1'>
          to do all such acts as the Portfolio Manager may consider to be necessary or desirable for
          the above or in order to perform its duties under this Agreement (including to do any
          currency conversion at the prevailing rate as reasonably determined by the Portfolio
          Manager where any payment is received or to be made in a different currency).
        </span>
      </li>
    </ol>
    <p className='c42 c22'>
      <span className='c1'></span>
    </p>
    <p className='c42'>
      <span className='c1'>Transactions Requiring Instructions</span>
    </p>
    <p className='c60 c22 c57 c108'>
      <span className='c1'></span>
    </p>
    <p className='c57 c60'>
      <span className='c1'>
        Without prejudice to the authority given to the Portfolio Manager above, the Portfolio
        Manager is authorised to carry out, via the Custodian, any transactions whatsoever relating
        to the Securities upon receipt of specific instructions, including instructions to deliver
        or deal with the Securities (for example pursuant to any sale), or to make payment for
        and/or receive Securities purchased by the Client.
      </span>
    </p>
    <p className='c54 c22 c81'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={15}>
      <li className='c37 c44 c57 li-bullet-43'>
        <span className='c1'>
          To the extent permitted under the laws of Mauritius, and except in the case of gross
          negligence on its part, the Portfolio Manager may not be held liable for any damage
          incurred to items held in the Account. &nbsp;In all cases, the Portfolio Manager&rsquo;s
          liability shall be limited to the damage proved by the Client, and to a maximum equal to
          the declared value.
        </span>
      </li>
    </ol>
    <p className='c37 c22 c57'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={16}>
      <li className='c37 c44 c57 li-bullet-25'>
        <span className='c1'>
          Withdrawal from the Account shall release the Portfolio Manager from any liability.
          &nbsp;The Client shall be solely responsible for insuring the items held in the Account.
        </span>
      </li>
    </ol>
    <p className='c37 c22 c57'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={17}>
      <li className='c37 c44 c57 li-bullet-39'>
        <span className='c1'>
          The Client acknowledges that the Portfolio Manager is subject to the general business
          conditions and safe custody regulations of the Custodian (&ldquo;
        </span>
        <span className='c18'>Custodian&rsquo;s T&amp;Cs</span>
        <span className='c1'>
          &rdquo;). In order for the Portfolio Manager to ensure its compliance with the
          Custodian&rsquo;s T&amp;Cs, the Client hereby agrees that:
        </span>
      </li>
    </ol>
    <p className='c37 c22 c57'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-1' start={17}>
      <li className='c23 li-bullet-48'>
        <span className='c1'>
          the Portfolio Manager may provide information regarding the Client, including confidential
          information and personal data as described in the General Terms and Conditions, to the
          Custodian for the purposes of meeting its obligations under the Custodian&rsquo;s
          T&amp;Cs, satisfying its internal due diligence requirements, and/or meeting its legal and
          regulatory obligations;
        </span>
      </li>
    </ol>
    <p className='c37 c22 c57'>
      <span className='c1'></span>
    </p>
    <p className='c37 c22 c57'>
      <span className='c1'></span>
    </p>
    <p className='c37 c22 c57'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-1' start={18}>
      <li className='c23 li-bullet-24'>
        <span className='c1'>
          the Client will, if requested by the Portfolio Manager or the Custodian, complete any and
          all relevant forms for the purposes of US tax law or any other fiscal requirements;
        </span>
      </li>
      <li className='c23 li-bullet-24'>
        <span className='c1'>
          the Client shall indemnify and hold harmless the Portfolio Manager for any liabilities,
          losses, costs and expenses incurred by the Portfolio Manager under the Custodian&rsquo;s
          T&amp;Cs, including, but not limited to, any costs incurred where the Client&rsquo;s
          orders exceed the funds available in the Client&rsquo;s Account, unless such liabilities,
          losses, costs or expenses are caused by the Portfolio Manager&rsquo;s gross negligence,
          wilful default or fraud;
        </span>
      </li>
      <li className='c23 li-bullet-25'>
        <span className='c1'>
          the Portfolio Manager expressly disclaims any liability for direct or indirect damage
          incurred by the Client in connection with transmission errors, transmission cutouts,
          technical defects, overload, service interruptions (e.g. systems maintenance),
          disruptions, interference, illegal intervention (e.g. hacking) and wilful blockage of
          telecommunications devices and networks (e.g. &ldquo;mail bombing&rdquo;, attacks intended
          to cripple services. etc.) or in connection with other malfunctions or deficiencies on the
          part of telecommunications and network operators; and
        </span>
      </li>
      <li className='c23 li-bullet-29'>
        <span className='c1'>
          the Portfolio Manager shall have the power to do all things necessary in relation to the
          Portfolio to satisfy its obligations under the Custodian&rsquo;s T&amp;Cs, provided that
          such actions are in accordance with these Terms.
        </span>
      </li>
    </ol>
    <p className='c22 c81 c98'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={18}>
      <li className='c42 c44 c57 li-bullet-24'>
        <span className='c1'>
          These provisions and conditions apply with immediate effect. &nbsp;The Client hereby
          declares that it has taken note of all clauses contained in these Terms and expressly
          agrees to the contents thereof.
        </span>
      </li>
    </ol>
    <p className='c42 c22 c81 c57'>
      <span className='c1'></span>
    </p>
    <ol className='c12 lst-kix_list_7-0' start={19}>
      <li className='c44 c88 li-bullet-30'>
        <span className='c1'>
          The Portfolio Manager reserves the right to amend these Terms at any time. Any such
          amendments shall be communicated to the Client by circular letter or by any other
          appropriate means. Unless an objection is made within one month of issue, they will be
          considered as approved and will replace all previous versions. The Portfolio
          Manager&rsquo;s General Terms and Conditions, which in particular stipulate that the
          Mauritian laws shall apply and designate the place of jurisdiction, and which the Client
          acknowledges that he has read and understood, shall govern all business relations between
          the Client and the Portfolio Manager, except where otherwise expressly stipulated in these
          Terms.
        </span>
        <span className='c18'>&nbsp;</span>
      </li>
    </ol>
    <div>
      <p className='c22 c91'>
        <span className='c86'></span>
      </p>
      <p className='c109 c22'>
        <span className='c84'></span>
      </p>
    </div>
  </body>
)

export { TermsOfBusiness }
