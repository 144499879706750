import dayjs from 'dayjs'
import { ASSET_RETURNS_TITLE } from './constants'

export const concatClassName = (classNames?: string[]) => {
  let concatClass = ''
  classNames &&
    classNames.map((className) => {
      concatClass = concatClass.concat(className).concat(' ')
    })
  return concatClass
}

export const stringToArray = (string: string) => {
  if (!string) return
  return string.split(';')
}

export const createSlug = (slug: string) => slug.toLowerCase().replace(/[^\w-]+/g, '-')

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const numberRegex = /^[1-9]\d*(\.\d+)?$/
export const calculateRemainingDays = (startDate: Date, endDate: Date): number => {
  // const startDate = Date.now()
  if (startDate.getTime() < endDate.getTime())
    return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
  else return 0
}

export const convertToNumber = (input: string): number => {
  let convertedToNumber = ''
  for (let i = 0; i < input.length; i++) {
    const hasNumber = /\d/
    if (hasNumber.test(input[i]) || input[i] === '.') {
      convertedToNumber += input[i]
    }
  }
  if (convertedToNumber.length > 0) return parseFloat(convertedToNumber)
  else return 0
}

export const convertNumberWithCommas = (input: string | number): string => {
  const val = typeof input === 'string' ? convertToNumber(input) : input
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const calculateCommittedPercentage = (
  committedAmount: string,
  raiseGoal: string,
): string => {
  try {
    const amount = convertToNumber(committedAmount.toString())
    const goal = convertToNumber(raiseGoal.toString())
    const remainingGoal = (amount * 100) / goal
    return remainingGoal.toFixed(2).toString().concat('%')
  } catch (err) {
    return '0%'
  }
}

export const calculatePercentage = (a: string, b: string) => {
  try {
    const first = convertToNumber(a.toString())
    const second = convertToNumber(b.toString())
    return (first * 100) / second
  } catch (err) {
    return 0
  }
}

export const roundPercentage = (percentage: number | string) => {
  try {
    return Math.floor(convertToNumber(percentage.toString()))
  } catch (err) {
    return 0
  }
}

export const isValidFileSize = (file: File, type: 'image' | 'doc') => {
  /* 1024 = 1MB */
  const size = Math.round(file.size / 1024)

  if (type === 'doc' && size <= 2 * 1024 * 10) {
    return true
  } else return type === 'image' && size <= 1024 * 10
}

export const isValidImageType = (file: File) => {
  const extension = file.name.substr(file.name.lastIndexOf('.'))

  /* define allowed file types */
  const allowedExtensionsRegx = /(\.jpg|\.jpeg)$/i

  /* testing extension with regular expression */
  return allowedExtensionsRegx.test(extension)
}

export const isValidDocType = (file: File) => {
  const extension = file.name.substr(file.name.lastIndexOf('.'))

  /* define allowed file types */
  const allowedExtensionsRegx = /(\.docx|\.pdf)$/i

  /* testing extension with regular expression */
  return allowedExtensionsRegx.test(extension)
}

export const findMatchingDoc = (name: string, arr: any[]) => arr.find((a) => a.field === name)

export const appendArrays = (array1: any[], array2: any[]) => {
  return [...array1, ...array2]
}

export const truncateNumber = (num: number, places = 2) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places)
}

export const displayPercentage = (value: string): string =>
  `${
    Number.isInteger(Number(value)) ? parseFloat(value).toFixed(0) : parseFloat(value).toFixed(2)
  }%`

export const displayDollar = (
  value: string | undefined,
  currencyCode: string | undefined,
  maximumFractionDigits = 2,
  forceStandardFormat = false,
) => {
  const oneBillion = 1000000000
  const oneMillion = 1000000
  const oneThousand = 1000
  const dollar = value && parseFloat(value) / 100
  let output = 0

  if (Number.isInteger(Number(value))) maximumFractionDigits = 0

  if (dollar && dollar >= oneBillion && !forceStandardFormat) {
    output = truncateNumber(dollar / oneBillion)
    return `${output.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD',
      maximumFractionDigits: maximumFractionDigits,
    })}b`
  } else if (dollar && dollar >= oneMillion && !forceStandardFormat) {
    output = truncateNumber(dollar / oneMillion)
    return `${output.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD',
      maximumFractionDigits: maximumFractionDigits,
    })}m`
  } else if (dollar && dollar >= oneThousand && !forceStandardFormat) {
    output = truncateNumber(dollar / oneThousand)
    return `${output.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD',
      maximumFractionDigits: maximumFractionDigits,
    })}k`
  }
  return dollar
    ? `${dollar.toLocaleString('en-US', {
        style: 'currency',
        currency: currencyCode || 'USD',
        maximumFractionDigits: maximumFractionDigits,
      })}`
    : '$0'
}

export const arrayToString = (array: string[]) => {
  if (!array) return
  return array.join(';')
}

export const removeEmptyValues = (obj: object) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v))

export const trimText = (text: string, charCount = 100) => {
  return text.length <= charCount ? text : text.substring(0, charCount).concat('...')
}

export const stringIfNull = (value: string | null) => {
  if (!value) return ''
  return value
}

export const displayStatus = (status: string) => {
  if (!status) return ''
  const lower = status.toLowerCase()
  const capitalize = lower[0].toUpperCase() + lower.slice(1)
  return capitalize.replace(/_/g, ' ').replace(/-/g, ' ')
}

export const getFileKeyFromSignedUrl = (signedUrl: string): string => {
  if (signedUrl.startsWith('http')) {
    const regexToExtractKey = /^.*\/([^/]+?)\?.*$/
    const match = signedUrl.match(regexToExtractKey)
    return match && match[1] ? match[1] : signedUrl
    // return signedUrl.split('/')[3].split('?')[0]
  }
  return signedUrl
}

export const setPreviousUrl = (url: string) => {
  const store = window.localStorage
  store.setItem('prevUrl', url)
}

export const getPreviousUrl = (): string => {
  const store = window.localStorage
  const prevUrl = store.getItem('prevUrl')
  if (prevUrl) return prevUrl
  return ''
}

export const setAuthToken = (authToken: string) => {
  const store = window.localStorage
  store.setItem('authToken', authToken)
}

export const getAuthToken = (): string => {
  const store = window.localStorage
  const authToken = store.getItem('authToken')
  if (authToken) return authToken
  return ''
}

export const removeAuthToken = () => {
  const store = window.localStorage
  store.removeItem('authToken')
}

export const formatDate = (date: Date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  if (!(date instanceof Date)) {
    throw new Error('Invalid input: expected a Date object.')
  }

  const year = date.getFullYear()
  const month = months[date.getMonth()].toUpperCase()
  const day = date.getDate()

  return `${year} ${month}, ${day}`
}

export const isMultiple = (x: number, y: number) => (x % y === 0 ? true : false)
export const truncateString = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength - 3)}...`
  }
  return text
}

export const convertDateToDayjs = (date: Date | undefined | null) => {
  return dayjs(date)
}

export const displayReturnsTitle = (assetClass?: string) => {
  return assetClass ? ASSET_RETURNS_TITLE[assetClass] : 'IRR'
}

export const getError = (errorObj: any) => {
  if (errorObj instanceof Date) {
    return errorObj.toString()
  }
  if (Array.isArray(errorObj)) {
    return errorObj.length > 0 ? errorObj[0] : ''
  }
  if (typeof errorObj === 'object' && errorObj !== null && !(errorObj instanceof Function)) {
    return JSON.stringify(errorObj)
  }
  return errorObj
}

export const getFormattedDate = (date: Date, format: string): any => {
  return dayjs(date).format(format)
}

export const convertToURLSearchParams = (obj: any) => {
  const params = new URLSearchParams()

  for (const key in obj) {
    params.append(key, obj[key])
  }

  return params
}
