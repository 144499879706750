import React from 'react'
import { Button, Flex, H3, H4, P } from '../../lib/components'
import { displayDollar } from '../../lib/utils/helpers'
import { useNavigate } from 'react-router-dom'
import { DashboardPortfolioSummaryProps } from '../../lib/types'

const DashboardPortfolioSummary = ({
  activeInvestmentsCount,
  closedInvestmentsCount,
  watchlistInvestmentsCount,
  totalInvestedAmount,
  totalReturnsTillDate,
}: DashboardPortfolioSummaryProps) => {
  const navigate = useNavigate()
  const computeInvestedAmountWidth = (num: number): number => {
    return (num / (totalInvestedAmount + totalReturnsTillDate)) * 100
  }
  return (
    <div className='dashboard-container__top-section'>
      {/* First section with investment and returns numbers */}
      <div className='dashboard-container__top-section__returns-section'>
        {!(totalInvestedAmount === 0 && totalReturnsTillDate === 0) && (
          <div className='dashboard-container__top-section__returns-section__progress-bar'>
            <div
              className='dashboard-container__top-section__returns-section__progress-bar__investment'
              style={{
                width: `${computeInvestedAmountWidth(totalInvestedAmount)}%`,
              }}
            />
            <div
              className='dashboard-container__top-section__returns-section__progress-bar__returns'
              style={{
                width: `${computeInvestedAmountWidth(totalReturnsTillDate)}%`,
              }}
            />
          </div>
        )}
        <Flex classes={['space-between']}>
          <div className='dashboard-container__top-section__returns-section__investment'>
            <Flex classes={['align-center']}>
              <div className='dashboard-container__top-section__returns-section__investment__legend'></div>
              <P text='TOTAL CAPITAL INVESTED' textSize='x-small' />
            </Flex>
            <H3 text={displayDollar(totalInvestedAmount.toString(), 'USD', 0, true)} />
          </div>

          <div className='dashboard-container__top-section__returns-section__returns'>
            <Flex classes={['align-center']}>
              <div className='dashboard-container__top-section__returns-section__returns__legend'></div>
              <P text='RETURNS TILL DATE' textSize='x-small' />
            </Flex>
            <H3 text={displayDollar(totalReturnsTillDate.toString(), 'USD', 0, true)} />
          </div>
        </Flex>
      </div>

      {/* Middle section with investment counts */}
      <div className='dashboard-container__top-section__investments-section'>
        <Flex classes={['align-center']}>
          <H4 text={activeInvestmentsCount.toString()} classes={['mr-20']} />
          <P text={'Active Investments'} />
        </Flex>
        <Flex classes={['align-center']}>
          <H4 text={closedInvestmentsCount.toString()} classes={['mr-20']} />
          <P text={'Past Investments'} />
        </Flex>
        <Flex classes={['align-center']}>
          <H4 text={watchlistInvestmentsCount.toString()} classes={['mr-20']} />
          <P text={'Watchlist'} />
        </Flex>
      </div>
      {/* Last section with buttons */}
      <div className='dashboard-container__top-section__controls-section'>
        <Button
          variant='primary-btn'
          text='Add Investment'
          handleClick={() => navigate('/opportunities')}
        />
        <Button
          variant='basic-btn'
          text='Get assistance'
          classes={['mt-16']}
          handleClick={() => navigate('/contact-us')}
        />
      </div>
    </div>
  )
}

export { DashboardPortfolioSummary }
