/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { HomePresenter } from './home-presenter'
import { RootState } from '../../store'
import { setPreviousUrl } from '../../lib/utils/helpers'
import { showBanner } from '../../global-state/banner-slice'
import { projectServices } from '../../services'
import { SearchParams } from '../../lib/types'
import { EVENTS } from '../../lib/utils/constants'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EngagementEvents, logCrmEvent } from '../../lib/utils/crmEvents'
import './home.scss'

const HomeContainer = () => {
  const [expand, setExpand] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [projects, setProjects] = useState([])
  const scrollRef = useRef<null | HTMLDivElement>(null)
  const { authToken, isEmailVerified, acceptedNDA } = useSelector(
    (state: RootState) => state.investor,
  )
  const searchParams: SearchParams = {
    sortBy: 'CREATED_AT_DESC',
    offset: '0',
    limit: '4',
  }

  // Caching
  const { data, error, isLoading } = useQuery({
    queryKey: ['opportunities'],
    queryFn: () => fetchAllOpportunities(),
  })

  const fetchAllOpportunities = async () => {
    return await projectServices.getSearchOpportunities(searchParams, {
      Authorization: authToken ? authToken : '',
    })
  }

  const handleButtonClick = () => {
    setExpand(true)
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  const handleProjectClick = (projectSlug: string, id: number) => {
    if (!!authToken && isEmailVerified) {
      if (!acceptedNDA) {
        setPreviousUrl(`/opportunity/${projectSlug}/${id}`)
      } else setPreviousUrl('')
      navigate(`/opportunity/${projectSlug}/${id}`)
    } else if (!!authToken && !isEmailVerified) {
      dispatch(
        showBanner({
          text: 'Please verify your email address to view the opportunity details.',
          variant: 'warning-banner',
        }),
      )
    } else {
      navigate('/login')
    }
  }

  useEffect(() => {
    if (data) {
      setProjects(data.opportunities || [])
    }
  }, [data, error, isLoading])

  useEffect(() => {
    logCrmEvent(EngagementEvents.visited_home, {
      Authorization: authToken ?? '',
    })

    // Capture Posthog event
    captureEvent(EVENTS.user_visited_credibila, {})
  }, [])

  return (
    <HomePresenter
      handleButtonClick={handleButtonClick}
      expand={expand}
      ref={scrollRef}
      handleProjectClick={handleProjectClick}
      projects={projects}
    />
  )
}

export { HomeContainer }
