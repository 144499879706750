/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  InvestmentCard,
  SearchIcon,
  Input,
  SelectDropdown,
  Button,
  H3,
  H4,
} from '../../lib/components'
import { DashboardPortfolioSummary } from './dashboard-portfolio-summary'
import { useBreakpoint } from '../../lib/utils/hooks'
import { ActiveInvestments } from './active-investments'
import { ClosedPortfolio } from './closed-portfolio'
import { WatchlistPortfolio } from './watchlist-portfolio'
import { DashboardPresenterProps } from '../../lib/types'

const DashboardPresenter = ({
  summaryProps,
  activeInvestments,
  closedInvestments,
  watchlistInvestments,
  changeActiveView,
  changeActiveInvestment,
}: DashboardPresenterProps) => {
  const navigate = useNavigate()
  const { breakpoint } = useBreakpoint()

  return (
    <div className='dashboard-container'>
      <H4 text='Portfolio Summary' />

      {/* Portfolio Summary Section */}
      <DashboardPortfolioSummary
        totalInvestedAmount={summaryProps.totalInvestedAmount}
        totalReturnsTillDate={summaryProps?.totalReturnsTillDate}
        activeInvestmentsCount={activeInvestments.investments.length}
        closedInvestmentsCount={closedInvestments.investments.length}
        watchlistInvestmentsCount={watchlistInvestments.watchlistOpportunities.length}
      />

      {/* Active Investments Section */}
      <ActiveInvestments
        averageReturn={activeInvestments.averageReturn}
        currentInvestmentValue={activeInvestments.currentInvestmentValue}
        investments={activeInvestments.investments}
        changeActiveView={changeActiveView}
        changeActiveInvestment={changeActiveInvestment}
      />

      {/* Closed Portfolio Section */}
      <ClosedPortfolio
        averageReturn={closedInvestments.averageReturn}
        currentInvestmentValue={closedInvestments.currentInvestmentValue}
        investments={closedInvestments.investments}
      />

      {/* Watchlist Portfolio Section */}
      <WatchlistPortfolio
        projectedReturn={watchlistInvestments.projectedReturn}
        watchlistOpportunities={watchlistInvestments.watchlistOpportunities}
      />
    </div>
  )
}

export { DashboardPresenter }
