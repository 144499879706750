/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import './payment.scss'
import {
  H2,
  Span,
  Anchor,
  H4,
  Image,
  Line,
  InputFile,
  PendingIcon,
  Button,
  P,
} from '../../lib/components'
import { displayDollar } from '../../lib/utils/helpers'
import bankDetails from './bank-details'
import { PLATFORM_FEE, PLATFORM_FEE_CURRENCY } from './constants'

const PaymentUploadDocument = ({
  project,
  investment,
  handleFileUpload,
  handleCompletePayment,
}: any) => {
  const [paymentCopy, setPaymentCopy] = useState(investment ? investment.paymentCopy : undefined)
  const fileData = {
    name: 'Payment copy',
    status: 'UPLOADED',
    lastModified:
      investment && investment.updatedAt && new Date(investment.updatedAt).getMilliseconds(),
  }
  const fileInfo = paymentCopy && paymentCopy.length > 0 ? fileData : []
  const isDisabled = !(paymentCopy && paymentCopy.length > 0)

  const handleFileChange = async (file: any) => {
    if (!file) return
    const response = await handleFileUpload(file)
    if (response && response.status === 200) {
      setPaymentCopy(response.data.key)
    }
  }
  const getTotalAmount = (...amounts: (string | number)[]): string => {
    return amounts.reduce((acc: number, b: string | number) => acc + Number(b ?? 0), 0).toString()
  }

  return (
    <>
      <div className='payment-upload-doc-heading-container'>
        <H2 text='Transfer funds' />
        <div>
          <Span text={'Payment process for'} />
          <Anchor
            classes={['invest-header-project-title']}
            text={project.projectName}
            href={`/opportunity/${project?.projectSlug}/${project?.id}`}
            variant='basic-anchor'
          />
        </div>
      </div>
      <div className='payment-warning-container-full mt-12 mb-12'>
        <PendingIcon classes={['info-icon']} width='75px' height='24px' />
        Please note that investors are responsible for bearing all SWIFT charges related to their
        transactions. Credibila will not cover or reimburse SWIFT charges under any circumstances.
        When initiating a wire transfer, please ensure you select the appropriate options to bear
        the SWIFT charges yourself.
      </div>
      <div className='payment-upload-doc-user-wrapper'>
        <div className='payment-upload-doc-user-wrapper-left'>
          <H4 classes={['h4-heading']} text='Wire transfer details' />

          <div className='payment-upload-doc-user-container'>
            <table className='payment-upload-doc-user-table'>
              <tbody>
                {Object.keys(bankDetails.usdClient).map((k) => (
                  <tr key={k}>
                    <td>{bankDetails.usdClient[k as keyof typeof bankDetails.usdClient].title}</td>
                    <td>{bankDetails.usdClient[k as keyof typeof bankDetails.usdClient].value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='acknowledgement-container'>
            <H4 text='Swift copy or bank acknowledgement' />
            <span className='sub-heading'>{'Please upload the payment slip below.'} </span>
            <InputFile
              setRemoveFile={() => setPaymentCopy('')}
              type='doc'
              label=''
              name='swiftCopyBank'
              handleFiles={handleFileChange}
              fileInfo={fileInfo}
            />
          </div>
          <div className='payment-warning-container'>
            <PendingIcon classes={['info-icon']} width='24px' height='24px' />
            The investment is subject to allocation decision of the fund manager and availability of
            allocation
          </div>
          <div className='payment-button-container'>
            <Button
              text='Complete payment'
              isDisabled={isDisabled}
              handleClick={() => {
                handleCompletePayment(paymentCopy)
              }}
              variant='primary-btn'
            />
          </div>
        </div>
        <div className='payment-upload-doc-user-wrapper-right'>
          <div className='payment-company-card'>
            <span className='summary-label'> {'Summary'} </span>
            <div className='company-image-container'>
              <Image source={project.projectCover} radius='r8' variant='round' />
              <span className='company-name'> {project.projectName} </span>
            </div>
            <div className='property-container'>
              <div className='property-row'>
                <span className='property-column-left'>{'Investment amount:'}</span>
                <span className='property-column-right'>
                  {displayDollar(investment?.amount, project.currencyCode, 2, true)}
                </span>
              </div>
              <div className='property-row'>
                <span className='property-column-left'>{'Platform fee:'}</span>
                <span className='property-column-right'>
                  {displayDollar(
                    ((investment?.platform_fee?.amount ?? PLATFORM_FEE) * 100).toString(),
                    PLATFORM_FEE_CURRENCY,
                  )}
                </span>
              </div>
            </div>
            <Line />
            <div className='property-container'>
              <div className='property-row'>
                <span className='property-column-left'>{'Total amount'}</span>
                <span className='property-column-right font-bold'>
                  {displayDollar(
                    getTotalAmount(
                      investment?.amount,
                      (investment?.platform_fee?.amount ?? PLATFORM_FEE) * 100,
                    ),
                    project.currencyCode,
                    2,
                    true,
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PaymentUploadDocument }
