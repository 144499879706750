import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

function HomepageImageCarousel({ images }: any) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  }

  return (
    <Carousel
      responsive={responsive}
      showDots
      infinite
      autoPlay
      renderDotsOutside
      transitionDuration={1000}
      customTransition='all 1s ease-out'
      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      dotListClass='react-multi-carousel-dot-list'
      containerClass='react-multi-carousel-list flex-9'
    >
      {images.map((src: any, idx: number) => (
        <div key={idx} className='homepage-carousel-item'>
          <img src={src} />
        </div>
      ))}
    </Carousel>
  )
}

export { HomepageImageCarousel }
