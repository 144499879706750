import React from 'react'

import { Button, Flex, InputModal } from '../'
import './popup-blocked-warning.scss'

const PopupBlockedWarningModal = () => (
  <InputModal>
    <Flex variant='column' classes={['popup-blocked']}>
      <h2>Action Required! Enable Popups to Proceed</h2>
      <p className='basic mt-14'>
        Some features require popups to be enabled. Please enable popups in your browser settings to
        continue.
      </p>
      <p className='mt-14'>Instructions:</p>
      <ol>
        <li className='basic'>
          <p>Open your browser settings.</p>
        </li>
        <li className='basic'>
          <p>{'Navigate to Privacy and Security > Site Settings.'}</p>
        </li>
        <li className='basic'>
          <p>Enable Pop-ups and Redirects.</p>
        </li>
      </ol>
      <Button
        classes={['mt-24']}
        text='Reload'
        variant='primary-btn'
        handleClick={() => window.location.reload()}
      />
    </Flex>
  </InputModal>
)

export default PopupBlockedWarningModal
