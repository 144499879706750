import React from 'react'
import { Flex, H2 } from '../../lib/components'
import { useBreakpoint } from '../../lib/utils/hooks'
import './about-us.scss'
import { MD } from '../../lib/utils/constants'

const AboutUs = () => {
  const { breakpoint } = useBreakpoint()

  return (
    <>
      {/* About us cover section */}
      <Flex classes={['about-us-cover-container', 'mt-24']}>
        <Flex variant='column' classes={['flex-justify-center']}>
          <H2 classes={['mb-24']} text='Making global investment accessible to everyone.' />
          <p>
            We strive to simplify investment opportunities for all, empowering individuals to grow
            their wealth without boundaries.
          </p>
        </Flex>

        <img
          src='https://gbc-credibila-assets.s3.amazonaws.com/images/about-cover.png'
          alt='about cover'
          className='about-us-cover'
        />
        <div className='about-us-cover-background'></div>
      </Flex>

      {/* About us founding team */}
      <div className={`about-us-team mb-72 ${breakpoint && breakpoint <= MD ? 'text-center' : ''}`}>
        <H2 text='Our Founding Team' />
        <Flex
          classes={[
            'mt-50',
            'gap-60',
            `${breakpoint && breakpoint <= MD ? 'column text-center flex-align-center' : ''}`,
          ]}
        >
          <img
            src='https://gbc-credibila-assets.s3.amazonaws.com/images/about-us-img1.png'
            alt='team 1'
            className='about-us-team-avatar'
          />
          <Flex variant='column' classes={['flex-justify-center']}>
            <Flex classes={[`${breakpoint && breakpoint <= MD ? 'flex-justify-center' : ''}`]}>
              <h3 className='about-us-team-heading'>
                <strong>Amardeep Sharma</strong> - Chairman
              </h3>
              <img
                src='https://gbc-credibila-assets.s3.amazonaws.com/opportunity-images/team-linkedIn.png'
                alt='linkedin'
                className='about-us-linkedin'
                onClick={() =>
                  window.open(
                    'https://www.linkedin.com/in/amardeep-sharma-b150926?originalSubdomain=ae',
                  )
                }
              />
            </Flex>

            <p className={`basic mt-12 ${breakpoint && breakpoint <= MD ? 'text-center' : ''}`}>
              An alumnus of the University of Oxford with more than two decades of experience in
              capital raising, investment banking, asset management, M&A, fund management, trade
              finance, structured products solutions, and renewable energy consulting & advisory,
              Mr. Amardeep Sharma, was honoured at the 24th Edition of the Asian Business and Social
              Forum: Greatest Brands and Leaders 2024 by AsiaOne Magazine. A distinguished leader in
              the financial sector, known for his strategic vision and expertise in investment
              management, Mr. Sharma has been driving success by delivering innovative financial
              solutions and fostering sustainable growth across various industries. In recognition
              of his exemplary leadership, being a trusted partner for businesses and investors,
              fostering long-term value creation through strategic investments and sound financial
              planning, he was honored with the prestigious Global Indian of the Year 2023-24 Award.
            </p>
          </Flex>
        </Flex>

        <Flex
          classes={[
            'gap-60',
            `${
              breakpoint && breakpoint <= MD ? 'column-reverse mt-72 flex-align-center' : 'mt-24'
            }`,
          ]}
        >
          <Flex variant='column' classes={['flex-justify-center']}>
            <Flex classes={[`${breakpoint && breakpoint <= MD ? 'flex-justify-center' : ''}`]}>
              <h3 className='about-us-team-heading'>
                <strong>Karan Rai</strong> - CTO
              </h3>
              <img
                src='https://gbc-credibila-assets.s3.amazonaws.com/opportunity-images/team-linkedIn.png'
                alt='linkedin'
                className='about-us-linkedin'
                onClick={() => window.open('https://www.linkedin.com/in/karanrai')}
              />
            </Flex>
            <p className={`basic mt-12 ${breakpoint && breakpoint <= MD ? 'text-center' : ''}`}>
              Mr. Karan Rai, an alumnus of the University of Oxford, brings over a decade of
              experience in technology entrepreneurship and product development. As the founder and
              CEO of Launch Ventures, he has been instrumental in creating high-performance teams
              that deliver innovative technology solutions for startups and established enterprises.
              Known for his commitment to addressing global challenges, Karan has led initiatives
              focused on climate change and misinformation. His leadership is characterized by a
              collaborative approach that empowers teams to build meaningful products, positioning
              Launch Ventures as a key player in the technology landscape.
            </p>
          </Flex>

          <img
            src='https://gbc-credibila-assets.s3.amazonaws.com/images/about-us-img2.png'
            alt='team 1'
            className='about-us-team-avatar'
          />
        </Flex>
      </div>

      {/* Our story */}
      <div className='about-us-wrapper-alpha'>
        <Flex classes={['about-us-content-container-alpha', 'gap-60', 'flex-align-center']}>
          <img
            src='https://gbc-credibila-assets.s3.amazonaws.com/images/about-us-story.png'
            alt='story'
            className='about-us-img'
          />
          <Flex variant='column' classes={['about-us-content']}>
            <H2 text='Our Story' />
            <p className='basic mt-24 regular'>
              The Credibila business model was expertly crafted by the visionary founding team, who
              recognized a golden opportunity to revolutionize the investment landscape. With a
              mission to make investing not just accessible, but truly empowering, they created a
              platform where opportunities shine brightly on the website, inviting everyone to
              explore and engage, with the ability to invest from as little as US $1000. Prospective
              investors can effortlessly evaluate potential gains and risks, investing with the ease
              of a single click. Understanding the importance of flexibility, many opportunities
              offer the freedom of early redemption. This forward-thinking approach led Credibila’s
              dynamic management to establish the company as an esteemed Investment Dealer, expertly
              facilitating securities transactions and paving the way for a brighter financial
              future for all.
            </p>
          </Flex>
        </Flex>
      </div>

      {/* Our values */}
      <div className='about-us-wrapper-bravo'>
        <Flex classes={['about-us-content-container-bravo', 'gap-60', 'flex-align-center']}>
          <Flex variant='column' classes={['about-us-content']}>
            <H2 text='Our Values' />
            <p className='basic mt-24 regular'>
              Investing can often seem daunting with its complex terminology and myriad options, but
              keeping the investment process simple can be a powerful strategy. Furthermore, a
              simplified investment process is instrumental in safeguarding investor’s interests. By
              stripping away unnecessary complexities, investors can maintain better control over
              their portfolios, ensuring transparency and ease in tracking progress.
            </p>
            <p className='basic mt-24 regular'>
              This is exactly what we aim to do here. Let us inspire you to embark on a journey
              where simplicity paves the way to success. By prioritizing your interests and
              maintaining a clear path forward, we can collectively achieve remarkable financial
              accomplishments. Together, we can transform the investment experience into one that is
              not only rewarding but also deeply fulfilling.
            </p>
          </Flex>

          <img
            src='https://gbc-credibila-assets.s3.amazonaws.com/images/about-us-values.png'
            alt='values'
            className='about-us-img'
          />
        </Flex>
      </div>

      {/* Our vision */}
      <div className='about-us-wrapper-alpha'>
        <Flex classes={['about-us-content-container-alpha', 'gap-60', 'flex-align-center']}>
          <img
            src='https://gbc-credibila-assets.s3.amazonaws.com/images/about-us-vision.png'
            alt='vision'
            className='about-us-img'
          />
          <Flex variant='column' classes={['about-us-content']}>
            <H2 text='Our Vision' />
            <p className='basic mt-24 regular'>
              Our company’s vision is to become the leading force in the investment landscape, where
              trust and excellences guide every decision.
            </p>
            <p className='basic mt-24 regular'>
              Our team comprises industry experts who are committed to identifying and capitalizing
              on the most promising ventures. We believe in the power of collaboration, working
              alongside our investors to tailor strategies that meet their unique needs and
              aspirations.
            </p>
            <p className='basic mt-24 regular'>
              Looking ahead, our vision is to continue setting benchmarks in the industry, inspiring
              confidence and fostering lasting partnerships. We invite prospective investors to join
              us on this remarkable journey, where their success is our priority, and together, we
              will achieve greatness. Let us transform ambition into reality and build a future
              where investment opportunities are not just sought after but are trusted and
              celebrated{' '}
            </p>
          </Flex>
        </Flex>
      </div>

      {/* Our mission */}
      <div className='about-us-wrapper-bravo'>
        <Flex classes={['about-us-content-container-bravo', 'gap-60', 'flex-align-center']}>
          <Flex variant='column' classes={['about-us-content']}>
            <H2 text='Our Mission' />
            <p className='basic mt-24 regular'>
              We aim to position ourselves as the top choice for investors by offering unparalleled
              opportunities that align with their financial goals and interests.
            </p>
            <p className='basic mt-24 regular'>
              Our commitment is to ensure that every opportunity we put on the website is backed by
              thorough research and strategic foresight, creating a secure and promising environment
              for growth of investors.
            </p>
            <p className='basic mt-24 regular'>
              At the core of our mission is the protection of our investor’s interests. We
              understand that trust is earned, not given, and we are dedicated to building and
              maintaining that trust through transparency and integrity. Our approach involves a
              meticulous evaluation of opportunities, ensuring that each investment is poised for
              success while safeguarding our investor’s assets.
            </p>
          </Flex>

          <img
            src='https://gbc-credibila-assets.s3.amazonaws.com/images/about-us-mission.png'
            alt='mission'
            className='about-us-img'
          />
        </Flex>
      </div>
    </>
  )
}

export { AboutUs }
