import { axiosInstance } from '.'
import {
  KYC_SUBMIT,
  GET_KYC,
  UPDATE_KYC_DOCUMENT,
  KYC_UPDATE,
  ACCEPT_NDA,
  ACCEPT_NDA_V1,
  GET_KYC_V1,
  GENERATE_SUMSUB_ACCESS_TOKEN,
  GENERATE_SUMSUB_SDK_LINK,
  ACCEPT_INVESTMENT_ESSENTIAL_AGREEMENT,
  ACCEPT_TOB,
} from './endpoints'
import { AcceptAgreement, SelfDeclaration } from '../lib/types'

export const kycServices = {
  acceptTNCAndNDA: async (
    selfDeclarationData: SelfDeclaration,
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.post(ACCEPT_NDA, selfDeclarationData, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },
  kycSubmit: async (kycDetails: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(KYC_SUBMIT, kycDetails, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },
  kycUpdate: async (kycDetails: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(KYC_UPDATE, kycDetails, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },
  getKYC: async (id: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_KYC(id), {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },
  updateKYCDocument: async (id: any, updateData: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(UPDATE_KYC_DOCUMENT(id), updateData, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  // v1
  acceptTNCAndNDA_v1: async (
    selfDeclarationData: SelfDeclaration,
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.post(ACCEPT_NDA_V1, selfDeclarationData, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  acceptInvestmentEssentialAgreement: async (
    declarationData: AcceptAgreement,
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.post(ACCEPT_INVESTMENT_ESSENTIAL_AGREEMENT, declarationData, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  acceptTermsOfBusinessAgreement: async (
    declarationData: AcceptAgreement,
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.post(ACCEPT_TOB, declarationData, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  getKYC_v1: async (id: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_KYC_V1(id), {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  generateKYCAccessToken: async (
    headers: { [key: string]: string },
    userId: string,
    levelName: string,
    ttlInSecs?: number,
  ) => {
    try {
      const payload = {
        userId,
        levelName,
        ttlInSecs,
      }
      return await axiosInstance.post(GENERATE_SUMSUB_ACCESS_TOKEN, payload, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  generateSumsubSDKLink: async (
    headers: { [key: string]: string },
    userId: string,
    entityName: string,
    ttlInSecs?: number,
  ) => {
    try {
      const payload = {
        userId,
        entityName,
        ttlInSecs,
      }
      return await axiosInstance.post(GENERATE_SUMSUB_SDK_LINK, payload, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },
}
