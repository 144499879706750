import React from 'react'
import { H4, Flex, P } from '../../lib/components'
import { HIGHLIGHTS } from '../../lib/utils/message'

interface HighlightsProps {
  highlights: string[]
}

const Highlights = ({ highlights }: HighlightsProps) => {
  return (
    <Flex variant='column'>
      <H4 text={HIGHLIGHTS} id='highlights' classes={['opportunity-details-heading']} />
      <Flex variant='column' classes={['mt-40', 'mr-50', 'ml-40', 'highlights-container']}>
        <Flex variant='row'>
          <ul>
            {highlights.map((highlight, idx) => (
              <li key={idx}>
                <P
                  text={highlight}
                  textSize={'regular'}
                  classes={['dark-gray', 'mr-32', 'align-justify']}
                />
              </li>
            ))}
          </ul>
        </Flex>
      </Flex>
    </Flex>
  )
}

export { Highlights }
