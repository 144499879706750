/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import './payment.scss'
import {
  H2,
  Span,
  Anchor,
  H4,
  Image,
  Line,
  P,
  Button,
  PrintIcon,
  PendingIcon,
  DownloadIcon,
  ApprovedIcon,
} from '../../lib/components'
import { useNavigate } from 'react-router-dom'
import { displayDollar } from '../../lib/utils/helpers'
import { PLATFORM_FEE, PLATFORM_FEE_CURRENCY } from './constants'

const PaymentSummary = ({
  handlePrintPaymentSummary,
  project,
  investmentData,
  handleShowContactUsModal,
  handleFilePreview,
}: any) => {
  const navigate = useNavigate()

  const SummaryData = [
    {
      title: 'The Credibila Team will review the investment request submitted. ',
      subTitle:
        'The Team will now review the subscription agreement document and payment swift copy uploaded by you. You will receive an email once the investment documents are reviewed and approved by the Credibila Team.',
    },
    {
      title:
        'Fund allocation is subject to the allocation decision of the fund manager and the availability of allocation.',
      subTitle:
        "The investment approval does not mean the fund units have been allocated to you. It is subject to the fund manager's discretion and the availability of fund units.",
    },
    {
      title: (
        <>
          In the case of the fund units are not allocated, contact the{' '}
          <span onClick={() => handleShowContactUsModal()}>
            {' '}
            <Anchor text='Credibila Team' href='' variant='basic-anchor' />{' '}
          </span>{' '}
          .
        </>
      ),
      subTitle:
        'The invested amount will be returned to you in the bank account mentioned in the swift copy uploaded within 10-12 business days.',
    },
  ]

  const getTotalAmount = (...amounts: (string | number)[]): string => {
    return amounts.reduce((acc: number, b: string | number) => acc + Number(b ?? 0), 0).toString()
  }

  return (
    <>
      <div className='payment-summary-heading-container'>
        <H2 text='Investment summary' />
        <div>
          <Span text={'Investment process for'} />
          <Anchor
            classes={['invest-header-project-title']}
            text={project.projectName}
            href={`/opportunity/${project?.projectSlug}/${project?.id}`}
            variant='basic-anchor'
          />
        </div>
      </div>
      <div className='payment-summary-wrapper'>
        <div className='payment-summary-wrapper-left'>
          <span> Further instructions </span>
          <div className='further-instruction-container'>
            {SummaryData &&
              SummaryData.map((obj, index) => {
                return (
                  <div key={obj.subTitle} className='further-instruction-row'>
                    <div className='further-instruction-column-left'>{index + 1}</div>
                    <div className='further-instruction-column-right'>
                      <H4 text={obj.title} />
                      <P variant='default-gray' text={obj.subTitle} />
                    </div>
                  </div>
                )
              })}
          </div>
          <div className='button-container'>
            <Button
              text='Go to dashboard'
              handleClick={() => {
                navigate('/dashboard')
              }}
              variant='primary-btn'
            />
            <Button
              text='View more opportunities'
              handleClick={() => {
                navigate('/opportunities')
              }}
              variant='basic-btn'
            />
          </div>
        </div>
        <div className='payment-summary-wrapper-right'>
          <div className='payment-company-card'>
            <span className='summary-label'> {'Summary'} </span>
            <div className='company-image-container'>
              <Image source={project.projectCover} radius='r8' variant='round' />
              <span className='company-name'> {project.projectName} </span>
            </div>
            <div className='property-container'>
              <P variant='default-gray' textSize='small' text={project.projectSummary} />
              <div className='property-row'>
                <span className='property-column-left'>{'Investment amount:'}</span>
                <span className='property-column-right'>
                  {displayDollar(investmentData?.amount, project.currencyCode, 2, true)}
                </span>
              </div>
              <div className='property-row'>
                <span className='property-column-left'>{'Platform fee:'}</span>
                <span className='property-column-right'>
                  {displayDollar(
                    ((investmentData?.platform_fee?.amount ?? PLATFORM_FEE) * 100).toString(),
                    PLATFORM_FEE_CURRENCY,
                  )}
                </span>
              </div>
            </div>
            <Line />
            {investmentData?.amount && project?.currencyCode && (
              <div className='property-container'>
                <div className='property-row'>
                  <span className='property-column-left'>{'Total amount'}</span>
                  <span className='property-column-right'>
                    {displayDollar(
                      getTotalAmount(
                        investmentData?.amount,
                        (investmentData?.platform_fee?.amount ?? PLATFORM_FEE) * 100,
                      ),
                      project.currencyCode,
                      2,
                      true,
                    )}
                  </span>
                </div>
              </div>
            )}
            {!investmentData?.fundAllocation && (
              <div className='payment-warning-container'>
                <PendingIcon classes={['info-icon']} width='24px' height='24px' />
                The investment is subject to allocation decision of the fund manager and
                availability of allocation
              </div>
            )}
            {investmentData?.fundAllocation && (
              <div className='payment-success-container'>
                <ApprovedIcon type='success' classes={['info-icon']} width='24px' height='24px' />
                Congratulations, Your payment has approved. You have allocated the Fund Allocation
                Document.
              </div>
            )}
          </div>
          <Button
            iconVariant='left'
            IconComponent={<PrintIcon classes={['mr-8']} />}
            text='Print summary'
            handleClick={handlePrintPaymentSummary}
            variant='basic-btn'
          />
          {investmentData?.fundAllocation && (
            <Button
              iconVariant='left'
              IconComponent={<DownloadIcon classes={['mr-8']} />}
              text='Fund allocation'
              buttonType='submit'
              handleClick={async () => {
                if (investmentData?.fundAllocation) {
                  const url = await handleFilePreview(investmentData?.fundAllocation)
                  url && window.open(url, '_blank')
                }
              }}
              variant='basic-btn'
            />
          )}
        </div>
      </div>
    </>
  )
}

export { PaymentSummary }
