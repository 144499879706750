import React from 'react'
import { H4, Flex } from '../../lib/components'
import { KEY_PERFORMANCE_INDICATOR } from '../../lib/utils/message'
import { KeyPerformanceProps } from '../../lib/types'
import './opportunity-html.scss'

const KeyPerformance = ({ keyPerformance }: KeyPerformanceProps) => {
  return (
    <Flex variant='column' classes={['mt-72']}>
      {keyPerformance.length > 0 && (
        <>
          <H4
            text={KEY_PERFORMANCE_INDICATOR}
            id='key-performance'
            classes={['opportunity-details-heading']}
          />
          <div className={'mt-32'} dangerouslySetInnerHTML={{ __html: keyPerformance }} />
        </>
      )}
    </Flex>
  )
}

export { KeyPerformance }
