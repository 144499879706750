import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex, ExpandMoreIcon, Anchor } from '../../lib/components'
import { RightArrow } from '../../lib/components/Icon'

interface faqAccordionProps {
  question: string
  answer?: string
  content?: React.ReactNode
  isActive: boolean
  setIsActive: (val: boolean) => void
}

const benefits = [
  {
    title: 'Am I eligible to invest?',
    content: (
      <>
        Whether you are an individual or institution, you may be eligible to invest. Once you
        register, we ask for a few documents to verify your identity and background. This is called
        a
        <Anchor variant='basic-anchor' classes={['ml-4']} href='/faq-details/General' text='KYC' />
        (Know your Client). Once you complete your KYC we will let you know your eligibility. It
        only takes a few minutes to register and complete your KYC. So what are you waiting for? Go
        grab that
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='/opportunities'
          text='Opportunity'
        />
        !
      </>
    ),
  },
  {
    title: 'Can I withdraw from an investment at any time?',
    content: (
      <>
        You can withdraw from an investment at anytime prior to transferring the funds towards your
        investment. Post transfer of funds, you may request our team or your relationship manager.
        Once your investment is complete, you may still withdraw from the investment by opting for
        redemption. Please check the redemption conditions on the
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='/opportunities'
          text='Opportunity'
        />{' '}
        page for more details.,
      </>
    ),
  },
  {
    title: 'How long does it take to process a KYC?',
    subTitle:
      'Most of our KYC process is automated so we strive to complete the process within 4 business hours of you submitting the application.\
    In case more information is required it could take longer but we keep you proactively informed throughout the process.',
  },
  {
    title: 'Do I have to accept the terms & condition?',
    href: '/Investing',
    subTitle:
      'Yes, You have to accept the terms & condition also accept the NDA for every opportunity. \
    We have made this process simple and seamless using technology so its easy and quick.',
  },
  {
    title: 'Can I invest in multiple opportunities?',
    content: (
      <>
        Yes, You can invest in multiple opportunities as long as you are within your
        <Anchor
          variant='basic-anchor'
          classes={['ml-4']}
          href='/faq-details/Investing'
          text='Investment Limit'
        />
        .
      </>
    ),
  },
  {
    title: 'How long does it take to process the investment?',
    subTitle:
      'Once you complete the investment application and transfer the funds, the Credibila team will review and confirm your investment within 6 business hours. If we need more information, it might take a bit longer, but  we’ll keep you updated via your registered email.',
  },
  {
    title: 'Have more questions?',
  },
]

const isMoreQuestionLink = (title: string) => title === 'Have more questions?'

const Accordion = ({ question, answer, content, isActive, setIsActive }: faqAccordionProps) => {
  const navigate = useNavigate()
  return (
    <div className='accordion'>
      <div className='accordion-item'>
        <div
          className='learn-more-accordion-title'
          onClick={() =>
            isMoreQuestionLink(question) ? navigate('/opportunities') : setIsActive(!isActive)
          }
        >
          <div className={`${isMoreQuestionLink(question) ? 'homepage-link' : ''}`}>{question}</div>
          {isMoreQuestionLink(question) ? (
            <RightArrow />
          ) : (
            <div className='learn-more-accordion-icon'>
              {isActive ? <ExpandMoreIcon classes={['learn-more-collapse']} /> : <ExpandMoreIcon />}
            </div>
          )}
        </div>
        {isActive && <div className='accordion-content'>{content ?? answer}</div>}
      </div>
    </div>
  )
}

const LearnMoreSection = () => {
  const [isActive, setIsActive] = useState(false)
  const [activeIndex, setIsActiveIndex] = useState<number | null>(null)

  return (
    <Flex variant='column' classes={['faq-accordion', 'mt-36']}>
      {benefits &&
        benefits.map(
          (
            {
              title,
              subTitle,
              content,
            }: { title: string; subTitle?: string; content?: React.ReactNode },
            index,
          ) => (
            <Accordion
              key={index}
              question={title}
              answer={subTitle}
              content={content}
              isActive={activeIndex === index ? isActive : false}
              setIsActive={(val: boolean) => {
                setIsActiveIndex(index)
                return setIsActive(val)
              }}
            />
          ),
        )}
    </Flex>
  )
}

export { LearnMoreSection }
