import React from 'react'
import { H4, Flex, Card, Image } from '../../lib/components'
import { ImageCarousel } from '../../lib/components/ImageCarousel'
import { ABOUT_THE_COMPANY } from '../../lib/utils/message'
import { AboutCompanyProps } from '../../lib/types'
import './opportunity-html.scss'

const AboutCompany = ({ aboutCompany, productImages, spaceAtTop = false }: AboutCompanyProps) => {
  return (
    <Flex variant='column' classes={[spaceAtTop ? 'mt-72' : '']}>
      {aboutCompany.length > 0 && (
        <>
          <H4
            text={ABOUT_THE_COMPANY}
            id='about-company'
            classes={['opportunity-details-heading']}
          />
          <div className={'mt-32'} dangerouslySetInnerHTML={{ __html: aboutCompany }} />
        </>
      )}
      {productImages && productImages.length > 0 && (
        <Card classes={['about-section-images-card', 'mt-40']}>
          <Flex classes={['overflow-y-hidden']}>
            {productImages?.map((src, idx) => (
              <Image
                key={idx}
                classes={['project-about-preview-image', 'mr-16']}
                source={src}
                variant='all'
                radius='r16'
              />
            ))}
          </Flex>
          <div className='mt-16'>
            <ImageCarousel
              images={productImages}
              prevLabel=''
              nextLabel=''
              imageClasses={['about-section-opportunity-image']}
              id='about'
            />
          </div>
        </Card>
      )}
    </Flex>
  )
}

export { AboutCompany }
