import React from 'react'
import './opportunity-card-component.scss'
import { Tag } from '../Tag'
import { Line } from '../Line'
import { ProjectListProps, LastMinuteListProps } from '../../types'
import {
  calculateRemainingDays,
  displayDollar,
  displayPercentage,
  displayReturnsTitle,
} from '../../utils/helpers'
import { H4 } from '../Heading'
import { Anchor } from '../Anchor'
import { captureEvent } from '../../utils/posthogUtils/usePostHog'
import { EVENTS, ASSET_CLASSES } from '../../utils/constants'
import { Flex } from '../Flex'

function OpportunityCard({
  id,
  projectSlug,
  projectName,
  assetClass,
  commitedPercentage,
  projectSummary,
  irr,
  ticketSize,
  projectCover,
  handleClick,
  closingDate,
  currencyCode,
}: ProjectListProps) {
  return (
    <div
      key={id}
      className='project-card-container'
      tabIndex={0}
      onClick={() => {
        captureEvent(EVENTS.opportunity_page_visited, {
          projectSlug,
          id,
          projectName,
          assetClass,
          ticketSize,
          projectCover,
          closingDate,
        })
        handleClick && handleClick(projectSlug, id)
      }}
    >
      <div className='project-card-container-cover'>
        <img src={projectCover} width={'100%'} height={'100%'} alt='project-display-image' />
      </div>
      <div className='project-card-container-wrapper'>
        <div className='title-container'>
          <h3 className='title'>{projectName}</h3>
        </div>

        <div className='opportunity-card-tag-container'>
          <div className='opportunity-card-asset '>{assetClass && <Tag text={assetClass} />}</div>

          <Flex classes={['opportunity-card-counter']}>
            <p
              className={
                closingDate && calculateRemainingDays(new Date(), new Date(closingDate)) > 0
                  ? 'text-center font-12 flex-1'
                  : 'text-center font-12 flex-1 closed-for-investment'
              }
            >
              {closingDate && calculateRemainingDays(new Date(), new Date(closingDate)) > 0 ? (
                <>
                  {closingDate && calculateRemainingDays(new Date(), new Date(closingDate))}
                  <br />
                  days left
                </>
              ) : (
                'Closed for investment'
              )}
            </p>
            <p className='text-center font-12 flex-1'>
              <span>{commitedPercentage}</span> committed
            </p>
          </Flex>
        </div>

        <Line />

        <div className='lower-container'>
          <div className='description-container'>
            <p>{projectSummary}</p>
          </div>

          <Line />

          <div className='stats-container'>
            {assetClass !== ASSET_CLASSES.LISTED_EQUITIES && irr !== '0.00' && (
              <div className='opportunity-card-stats-card'>
                <p className='card-main'>
                  {assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                    ? displayDollar(irr, currencyCode)
                    : displayPercentage(irr) || ''}
                </p>
                <p className='card-sub'>{displayReturnsTitle(assetClass)}</p>
              </div>
            )}
            <div className='opportunity-card-stats-card'>
              <p className='card-main'>
                {ticketSize && currencyCode
                  ? displayDollar(ticketSize, currencyCode, 0)
                  : ticketSize}
              </p>
              <p className='card-sub'>Ticket size</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { OpportunityCard }

function OpportunityCardHorizontal({
  id,
  projectSlug,
  projectName,
  commitedPercentage,
  projectSummary,
  projectCover,
  handleClick,
  closingDate,
}: LastMinuteListProps) {
  return (
    <div
      className='project-h-container'
      onClick={() => {
        captureEvent(EVENTS.opportunity_page_visited, { projectSlug, id, projectName })
        handleClick && handleClick(projectSlug, id)
      }}
    >
      <div className='project-h-image'>
        <img src={projectCover} alt='' />
      </div>
      <div className='project-h-content'>
        <div className='project-h-text'>
          <H4 text={projectName} />
          <p>
            {projectSummary} &nbsp;{' '}
            <Anchor
              text='know more'
              href={`/opportunity/${projectSlug}/${id}`}
              variant='basic-anchor'
            />
          </p>
        </div>
        <div className='project-h-stats'>
          <p>
            <strong>{commitedPercentage}</strong> committed
          </p>
          <p>
            {closingDate && calculateRemainingDays(new Date(), new Date(closingDate))}{' '}
            <strong>days</strong>
          </p>
        </div>
      </div>
    </div>
  )
}

export { OpportunityCardHorizontal }
