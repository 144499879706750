import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Card } from '../Card'
import { Flex } from '../Flex'
import { InputModal } from '../InputModal'
import { InvestmentEssentialsFormModalProps } from '../../types'
import { InvestmentEssentialsForm } from './investment-essentials-modal-form'
import { kycServices } from '../../../services'
import { RootState } from '../../../store'
import './investmentEssentialsModal.scss'

const InvestmentEssentials = ({ active, handleOnClose }: InvestmentEssentialsFormModalProps) => {
  const { authToken, userId } = useSelector((state: RootState) => state.investor)
  const [isChecked, setChecked] = useState<boolean>(false)

  const handleCheckboxClick = async () => {
    const data: any = await kycServices.acceptInvestmentEssentialAgreement(
      { declaration: true },
      {
        Authorization: authToken ? authToken : '',
      },
    )

    if (data && data.data && data.status === 200) {
      setChecked(!isChecked)
      setTimeout(() => handleOnClose(), 1000)
    }
  }

  useEffect(() => {
    if (active && !isChecked) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [active, isChecked])

  useEffect(() => {
    ;(async () => {
      const response: any = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })
      if (!!response && response.data && response.status === 200) {
        setChecked(response.data.investment_essentials_agreement)
      }
    })()
  }, [isChecked])

  return active ? (
    <InputModal>
      <Card classes={['p-24 investment-essentials-modal']}>
        <Flex variant='space-between' classes={['mb-24']}></Flex>
        <div
          className='self-declaration-wrapper self-declaration-wrapper-overflow mb-24'
          style={{ maxHeight: 'calc(100% - 110px)' }}
        >
          <div className='self-declaration-wrapper-text'>
            <h1 className='text-align-center mb-24' style={{ lineHeight: '120%' }}>
              Investing and Risks Associated with Investing
            </h1>
            <p>
              Investing in today&apos;s dynamic market landscape can be a rewarding yet challenging
              endeavour. For both new and seasoned investors, understanding the myriad of risks
              involved is crucial to making informed decisions. The market&apos;s inherent
              volatility means that prices can fluctuate rapidly, influenced by a variety of
              factors.
            </p>
            <br />
            <p>
              <b>Currency Risk:</b> Involves the potential loss from changes in exchange rates,
              affecting international investments. For example, an investor holding European assets
              may see their returns diminished if the euro depreciates against the dollar.
            </p>
            <br />

            <p>
              <b>Inflation Risk:</b> Rising prices can erode the purchasing power of returns over
              time. An investment yielding 3% annually may effectively lose value if inflation is at
              4%.
            </p>
            <p>
              <b>Liquidity Risk:</b> The challenge associated with converting an investment into
              cash without significant loss of value. For example, real estate investments may take
              months to sell without incurring a loss.
            </p>
            <br />

            <p>
              <b>Credit Risk:</b> The possibility that a borrower may default on their obligations,
              impacting the investor&apos;s returns. A classic instance is when a company fails to
              pay interest on its bonds, affecting bondholders.
            </p>
            <br />

            <p>
              <b>Concentration Risk:</b> Arises when a portfolio lacks diversification, potentially
              leading to significant losses if one sector or investment performs poorly, such as
              during the dot-com bubble burst when tech-heavy portfolios suffered major declines.
            </p>
            <br />

            <p>
              Other risks to consider include:
              <br />- <b>Default Risk:</b> When an issuer fails to make required payments.
              <br />- <b>Reinvestment Risk:</b> Occurs when future cash flows, such as coupon
              payments, are reinvested at a lower interest rate.
              <br />- <b>Interest Rate Risk:</b> Pertains to the impact of fluctuating rates on the
              value of investments, especially bonds.
              <br />- <b>Longevity Risk:</b> A unique challenge for retirement planning, where
              there&apos;s a concern about outliving one&apos;s financial resources.
            </p>
            <br />

            <p>
              Additionally, changes in regulations or government policies can significantly impact
              investment outcomes. For instance, new environmental laws could affect profitability.
            </p>
            <br />

            <h2 className='text-align-center mb-24' style={{ lineHeight: '120%' }}>
              Are there any Guarantees?
            </h2>
            <p>
              Investing in the ever-changing market can be both exciting and daunting for investors.
              While the potential for growth is significant, it&apos;s natural to wonder whether
              there are any guarantees regarding the protection of your capital. It&apos;s important
              to understand that the market, by its very nature, is unpredictable and subject to
              fluctuations. However, there are strategies and considerations that can help manage
              risks and safeguard investments.
            </p>
            <br />

            <p>
              <b>Diversification:</b> A key strategy in managing investment risk. By spreading
              investments across different asset classes, industries, or geographies, investors can
              reduce the impact of any single market downturn on their overall portfolio. While it
              doesn&apos;t guarantee against loss, it can provide a buffer against volatility.
            </p>
            <br />

            <p>
              <b>Time Horizon:</b> Markets tend to experience cycles of ups and downs, and while
              short-term fluctuations can be unsettling, a long-term approach often smooths out
              these variations. Historical data suggests that the longer you remain invested, the
              greater the likelihood of achieving positive returns.
            </p>
            <br />

            <p>
              <b>Risk Tolerance:</b> Understanding your appetite for risk is crucial. Aligning your
              investment choices with your comfort level is essential. For example, government bonds
              offer more stability than stocks, which might be more volatile but offer higher
              potential returns.
            </p>
            <br />

            <p>
              While no investment is entirely without risk, working with a financial advisor can
              provide guidance tailored to your individual goals and circumstances. Advisors can
              help craft a balanced portfolio that aligns with your objectives while considering the
              inherent risks in the market.
            </p>
            <br />

            <p>
              <b>In Conclusion:</b> While there are no absolute guarantees in investing, being
              informed and strategic can help protect your capital. By diversifying, understanding
              your risk tolerance, and adopting a long-term perspective, you can navigate the
              market&apos;s uncertainties with greater confidence.
            </p>
          </div>
        </div>
        <InvestmentEssentialsForm handleCheckboxClick={handleCheckboxClick} isChecked={isChecked} />
      </Card>
    </InputModal>
  ) : null
}
export { InvestmentEssentials }
