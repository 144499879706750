import React, { useEffect, useState, useRef } from 'react'
import { Card } from '../Card'
import { Flex, FlexCenter } from '../Flex'
import { InputModal } from '../InputModal'
import { Close } from '../Icon'
import { H4 } from '../Heading'
import { contactUsDetailsSchema } from '../../utils/schema'
import { GuestContactUsFormProps_v1, ContactUsModalProps } from '../../types'
import { ContactUsForm } from './contact-us-form'
import { userServices } from '../../../services'
import { contactsServices } from '../../../services/contact-us-services'
import { StatusLabel } from '../StatusLabel'
import './contact-us.scss'
import { useDispatch, useSelector } from 'react-redux'
import { showBanner } from '../../../global-state/banner-slice'
import { RootState } from '../../../store'
import ReCAPTCHA from 'react-google-recaptcha'

const ContactUsModal = ({ active, subject, handleOnClose }: ContactUsModalProps) => {
  const reRef = useRef<ReCAPTCHA>(null)
  const dispatch = useDispatch()
  const [apiResponse, setApiResponse] = useState<{
    type: 'success' | 'error'
    message: string
  }>({
    type: 'success',
    message: '',
  })

  const { authToken, userId, email } = useSelector((state: RootState) => state.investor)
  const [initialValues, setInitialValues] = useState<GuestContactUsFormProps_v1>({
    firstName: '',
    lastName: '',
    email: email,
    subject: subject || 'General',
    message: '',
  })
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null)

  useEffect(() => {
    const getLoggedInUser = async () => {
      const response: any = userServices.getProfileForCurrentUser({
        Authorization: authToken ? authToken : '',
      })
      if (response.status === 200) {
        setInitialValues({
          ...initialValues,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email || email,
        })
      }
    }
    if (authToken) getLoggedInUser()
  }, [])

  const handleSubmit = async (values: GuestContactUsFormProps_v1) => {
    reRef.current?.reset()

    contactsServices
      .submitGuestContactForm_v1(values, {
        'g-recaptcha-response': captchaToken ? captchaToken : '',
      })
      .then((response) => {
        setApiResponse({
          type: 'success',
          message: response.data.message,
        })

        dispatch(
          showBanner({
            text: 'Query submitted successfully',
            variant: 'success-banner',
          }),
        )
        handleOnClose()
      })
      .catch((err) => {
        const message = err.response.data ? err.response.data.message : 'Error recording your data'
        setApiResponse({
          type: 'error',
          message: message,
        })
      })
  }

  return (
    <div>
      {active ? (
        <InputModal>
          <FlexCenter variant='column' classes={['contact-us-modal-container']}>
            <div className='contact-us-card-container p-24 contact-us-form'>
              <Flex variant='space-between' classes={['mb-32']}>
                <H4 text='Contact Us' />
                <Close type='default-light' onClick={handleOnClose} />
              </Flex>
              <ContactUsForm
                validationSchema={contactUsDetailsSchema}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                reRef={reRef}
                captchaToken={captchaToken}
                setCaptchaToken={setCaptchaToken}
              />
              {apiResponse.message.length > 0 && (
                <FlexCenter>
                  <StatusLabel
                    type={apiResponse.type}
                    text={apiResponse.message}
                    variant={'bold'}
                  />
                </FlexCenter>
              )}
            </div>
          </FlexCenter>
        </InputModal>
      ) : null}
    </div>
  )
}
export { ContactUsModal }
